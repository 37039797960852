<template>
  <details
    class="rounded-md"
    :class="{ 'bg-zinc-500/20 dark:bg-zinc-600/30': isRouteActive }"
    :open="isRouteActive"
    @click="isOpen = !isOpen"
  >
    <summary
      class="cursor-pointer flex items-center py-2 px-3 rounded-md hover:bg-neutral-600/10 dark:hover:bg-neutral-600 transition-all"
    >
      <svg
        class="w-5 h-5 mr-3"
        enable-background="new 0 0 24 24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <g><rect fill="none" height="24" width="24" /></g>
        <g>
          <g>
            <rect height="4" width="4" x="10" y="4" />
            <rect height="4" width="4" x="4" y="16" />
            <rect height="4" width="4" x="4" y="10" />
            <rect height="4" width="4" x="4" y="4" />
            <rect height="4" width="4" x="16" y="4" />
            <polygon points="11,17.86 11,20 13.1,20 19.08,14.03 16.96,11.91" />
            <polygon points="14,12.03 14,10 10,10 10,14 12.03,14" />
            <path
              d="M20.85,11.56l-1.41-1.41c-0.2-0.2-0.51-0.2-0.71,0l-1.06,1.06l2.12,2.12l1.06-1.06C21.05,12.07,21.05,11.76,20.85,11.56z"
            />
          </g>
        </g>
      </svg>
      <span class="text-sm">{{ $t("management") }}</span>
    </summary>
    <ul>
      <li>
        <Link
          :href="$route('management.logo')"
          class="flex items-center py-2 px-3 group rounded-md hover:bg-neutral-600/10 dark:hover:bg-neutral-200/10 transition-all"
        >
          <span
            class="text-sm relative pl-8 before:absolute before:content-[''] before:left-1.5 before:top-2 before:w-1.5 before:h-1.5 before:rounded-full group-hover:before:bg-red-600"
            :class="[
              currentRoute === 'management.logo'
                ? 'before:bg-red-600 dark:before:bg-red-600'
                : 'before:bg-zinc-500/50 dark:before:bg-zinc-300/80',
            ]"
          >
            {{ $t("logo") }}
          </span>
        </Link>
      </li>
    </ul>
  </details>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import { inject, ref, watch } from "@vue/runtime-core";

export default {
  components: { Link },
  props: ["isOpen"],
  setup() {
    let currentRoute = inject("currentRoute");
    let isRouteActive = ref(false);
    function checkRoute(route) {
      if (
        route === "management.resellers" ||
        route === "management.audios" ||
        route === "management.firewall" ||
        route === "management.sip-servers" ||
        route === "management.system-variables" ||
        route === "management.logo"
      ) {
        return true;
      }

      return false;
    }
    watch(
      currentRoute,
      () => (isRouteActive.value = checkRoute(currentRoute.value))
    );

    return {
      currentRoute,
      isRouteActive,
    };
  },
};
</script>
