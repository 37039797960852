import axios from "axios";

const baseURL = process.env.MIX_API_URL;

function getLocalStorageInfos() {
  let infos = localStorage.getItem("auth_payload");
  return (infos = JSON.parse(infos));
}

let authInfos = getLocalStorageInfos();

let config = {
          baseURL: baseURL + '/api',
};

if (authInfos) {
          config.headers = {
                        Authorization: `${authInfos.token_type} ${authInfos.access_token}`,
                      };

}

//let config = {
//  baseURL: baseURL + '/api',
//  headers: {
//    Authorization: `${authInfos.token_type} ${authInfos.access_token}`,
//  },
//};

console.log('admin.baseURL', baseURL);
//console.log('admin.basePATH', basePATH);

let request = axios.create(config);
let counter = 0;

async function refreshToken(infos) {
  request.defaults.baseURL = baseURL;
  return request.post("/oauth/token", infos);
}

request.interceptors.request.use(
  function (response) {
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response) {
    request.defaults.baseURL = baseURL + '/api';

    authInfos = getLocalStorageInfos();
    const AUTH_TOKEN = `${authInfos.token_type} ${authInfos.access_token}`;
    request.defaults.headers["Authorization"] = AUTH_TOKEN;

    return response;
  },
  function (error) {
    const {
      config,
      response: { status },
    } = error;

    const originalRequest = config;
    originalRequest._retry = true;

    if (status === 401) {
      counter++;
      console.log(counter);

      if (counter > 10) return Promise.reject(error);

      let infosToRefresh = {
        grant_type: "refresh_token",
        refresh_token: authInfos.refresh_token,
        client_id: authInfos.client_id,
        client_secret: authInfos.client_secret,
        scope: "",
      };

      refreshToken(infosToRefresh).then((res) => {
        const AUTH_TOKEN = `${res.data.token_type} ${res.data.access_token}`;
        let auth_payload = {
          access_token: res.data.access_token,
          refresh_token: res.data.refresh_token,
          token_type: res.data.token_type,
          expires_in: res.config.expires_in,
          client_id: authInfos.client_id,
          client_secret: authInfos.client_secret,
        };

        localStorage.setItem("auth_payload", JSON.stringify(auth_payload));

        if (originalRequest.url.includes("dashboard")) {
          window.location.reload();
        }

        request.defaults.headers["Authorization"] = AUTH_TOKEN;
        request.defaults.baseURL = baseURL + '/api';
        originalRequest.headers["Authorization"] = AUTH_TOKEN;

        request(originalRequest);
      });
    }
    return Promise.reject(error);
  }
);

export default request;
