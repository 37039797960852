import { defineStore } from "pinia";
import request from "../http";

const user = JSON.parse(localStorage.getItem("auth_payload"));

export const usePabxStore = defineStore("usePabxStore", {
  state: () => {
    return {
      pabxList: [],
      templateList: [{ id: null, name: "Nenhum selecionado" }],
    };
  },
  actions: {
    async getPabxList(
      favorites = 0,
      status = "",
      text = "",
      central_id = "",
      url = ""
    ) {
      if (!user.user_id) return;

      return request.get("/core_company", {
        params: {
          favoritos: favorites,
          status,
          name: text,
          central_id,
          url,
        },
      });
    },

    async getPabxPerServer(server) {
      if (server >= 0) {
        return request.get(`/core_company/server/${server}`);
      }
    },

    async getServerSip() {
      return request.get("/core_server");
    },

    async getServerSipPabx(serverId) {
      return request.get(`/core_server_pabx/${serverId}`);
    },

    async getTemplates(sipServerId) {
      if (sipServerId) {
        return request.get(`/template?id_sip_server=${sipServerId}`);
      }

      return request.get("/template");
    },

    async getServerTemplates(serverVersion) {
      return request.get(`/template/version/${serverVersion}`);
    },

    async getServerPlan() {
      return request.get("/plan");
    },

    async getNewPabxInfos() {
      return Promise.all([this.getServerSip(), this.getServerPlan()]);
    },

    async createNewPabx(server, newPabx) {
      return request.post(`/server/${server}/core_company`, newPabx);
    },

    async getPabxInfosToEdit(server, pabxId) {
      return request.get(`/server/${server}/core_company/${pabxId}`);
    },

    async editPabxInfos(server, pabxId, infos) {
      return request.put(`/server/${server}/core_company/${pabxId}`, infos);
    },

    async deletePabx(server, pabxId) {
      return request.delete(`/server/${server}/core_company/${pabxId}`);
    },

    async toggleFavoritePabx(pabxId, isFavorite) {
      return request.get(`/core_company/favorite/${pabxId}/${isFavorite}`);
    },

    async getStatusToEdit() {
      return request.get("core_company_status");
    },
  },
});
