<template>
  <div
    class="w-full rounded-lg bg-white dark:bg-zinc-800 shadow shadow-zinc-300 hover:shadow-none dark:shadow-zinc-900"
  >
    <header class="flex items-start justify-between m-4 gap-x-5">
      <div class="flex flex-col">
        <h1
          class="text-zinc-500 dark:text-zinc-300 max-w-[230px] truncate font-medium"
        >
          {{ template.name }}
        </h1>
        <p class="text-zinc-400 dark:text-zinc-500 text-xs">
          {{ template.type }} - {{ template.core_server }}
        </p>
      </div>
      <div class="relative">
        <button
          @click="isOpen = 'edit-template-dropdown'"
          class="relative text-zinc-500 dark:text-zinc-300"
        >
          <DotsVerticalIcon class="w-6 h-6" />
          <span class="sr-only">{{ $t("toggle_edit_template_options") }}</span>
        </button>
        <Transition name="menu">
          <ul
            v-if="isOpen === 'edit-template-dropdown'"
            class="absolute right-5 top-8 bg-white dark:bg-zinc-800 text-zinc-500 dark:text-zinc-300 shadow shadow-zinc-300 dark:shadow-zinc-900 rounded-md overflow-hidden w-32"
            ref="templateDropdownMenu"
          >
            <li>
              <button
                @click="$emit('handleEditTemplate', template)"
                class="p-3 flex items-center gap-3 hover:bg-zinc-100 dark:hover:bg-zinc-700/80 w-full"
              >
                <PencilIcon class="w-4 h-4" />
                <span>{{ $t("edit") }}</span>
              </button>
            </li>
            <li>
              <button
                class="p-3 flex items-center gap-3 hover:bg-zinc-100 dark:hover:bg-zinc-700/80 w-full"
                @click="$emit('handleDeleteTemplate', template)"
              >
                <TrashIcon class="w-4 h-4" />
                <span>{{ $t("delete") }}</span>
              </button>
            </li>
          </ul>
        </Transition>
      </div>
    </header>
    <div class="flex items-center gap-5 m-4 text-xs">
      <span
        class="bg-emerald-500 dark:bg-emerald-600 pt-0.5 pb-1 px-2 rounded-full text-white"
      >
        {{ $t("operating") }}
      </span>
      <span
        class="bg-zinc-500 dark:bg-zinc-600 pt-0.5 pb-1 px-2 rounded-full text-white"
      >
        {{ template.ramais }} {{ $t("branchs") }}
      </span>
    </div>
    <footer
      class="border-t border-zinc-200 dark:border-zinc-700 text-xs 2xl:text-sm"
    >
      <a
        :href="template.url_template_pcc"
        target="_blank"
        class="cursor-pointer rounded-b-lg hover:bg-zinc-200 dark:hover:bg-zinc-800 w-full py-4 flex items-center justify-center gap-1 text-zinc-600/80 dark:text-zinc-400/80"
      >
        <ExternalLinkIcon class="w-5 h-5" />
        <span>{{ $t("access") }}</span>
      </a>
    </footer>
  </div>
</template>
<script>
import { Link } from "@inertiajs/inertia-vue3";
import Toggle from "./Toggle.vue";
import { ref } from "@vue/reactivity";
import { useTemplateStore } from "../../../../../store/TemplateStore.js";
import { onClickOutside } from "@vueuse/core";
import {
  PencilIcon,
  TrashIcon,
  ExternalLinkIcon,
  CheckIcon,
  DotsVerticalIcon,
  UsersIcon,
} from "@heroicons/vue/solid";

export default {
  components: {
    Link,
    Toggle,
    PencilIcon,
    TrashIcon,
    ExternalLinkIcon,
    CheckIcon,
    DotsVerticalIcon,
    UsersIcon,
  },
  props: ["template"],
  emit: [
    "closeModal",
    "handleDeleteTemplate",
    "handleNotification",
    "handleEditTemplate",
  ],
  setup(props, { emit }) {
    const TemplateStore = useTemplateStore();
    let isOpen = ref(false);
    let isSelected = ref(true);

    const templateDropdownMenu = ref(null);

    onClickOutside(templateDropdownMenu, () => (isOpen.value = false));

    let currentTemplateName = ref(null);
    let isNameValid = ref(true);

    const closeModal = () => emit("closeModal");

    const checkName = () => {
      if (currentTemplateName.value.value.length < 3) {
        isNameValid.value = false;
        currentTemplateName.value.focus();
        return;
      }

      isNameValid.value = true;
    };
    const submitNewTemplateName = () => {
      if (!isNameValid.value) return;

      TemplateStore.editTemplateName(props.template)
        .then(({ data }) => {
          const notificationInfos = {
            msg: data.message,
            status: "success",
            show: true,
          };

          emit("handleNotification", notificationInfos);
        })
        .catch((error) => {
          const notificationInfos = {
            msg: error,
            status: "error",
            show: false,
          };

          emit("handleNotification", notificationInfos);
        });
      isOpen.value = false;
    };

    return {
      props,
      emit,
      isOpen,
      isSelected,
      closeModal,
      submitNewTemplateName,
      TemplateStore,
      currentTemplateName,
      isNameValid,
      checkName,
      templateDropdownMenu,
    };
  },
};
</script>
