<template>
  <div class="flex flex-wrap gap-x-5 gap-y-6 relative">
    <div class="w-full">
      <span
        class="text-xs text-zinc-500 dark:text-zinc-500/80 uppercase mb-2 block"
      >
        {{ $t("template_name") }}
      </span>
      <input
        type="text"
        form="newTemplate"
        class="peer border outline-none border-zinc-200 dark:border-zinc-700 py-1.5 rounded-md w-full dark:bg-zinc-700/30 px-3 text-sm focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 font-mono placeholder:text-zinc-500 placeholder:text-xs placeholder:font-medium"
        minlength="3"
        :class="[
          isNameValid()
            ? 'text-emerald-500 border-emerald-500 dark:border-emerald-600'
            : 'text-red-500 dark:text-red-600 border-red-500 dark:border-red-600',
        ]"
        :placeholder="$t('insert_a_name')"
        v-model="template.name"
        ref="templateName"
      />
    </div>

    <div class="w-full flex justify-end gap-5">
      <CloseButton :closeText="$t('close')" @click="$emit('closeModal')" />
      <SaveButton :saveText="$t('save')" @click="createTemplate" />
    </div>
  </div>
</template>

<script>
import Select from "../../../../components/select/Index.vue";
import SaveButton from "../../../../components/buttons/TheSaver.vue";
import CloseButton from "../../../../components/buttons/TheCloser.vue";
import { useTemplateStore } from "../../../../../store/TemplateStore.js";

export default {
  name: "PabxForm",
  components: {
    Select,
    SaveButton,
    CloseButton,
  },
  emits: ["closeModal", "notificate", "refreshList"],
  props: ["selectSipServer"],
  data() {
    return {
      TemplateStore: useTemplateStore(),
      template: {},
      currentServer: null,
    };
  },
  methods: {
    removeNullValues(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );
    },
    handleChoosenServer(currentValue) {
      this.currentServer = currentValue.id;
      this.template.id_core_servers = currentValue.id;
    },
    isNameValid() {
      if (
        !this.template.name ||
        this.template.name === "" ||
        this.template.name.length < 3
      ) {
        return false;
      }

      return true;
    },
    createTemplate() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.template.id_usuario_revendedor = user.usu_id;

      const auth_payload = JSON.parse(localStorage.getItem('auth_payload'));
      this.template.id_core_servers = auth_payload.resale_cs_id;

      if (!this.isNameValid()) {
        this.$refs.templateName.focus();
        return;
      }

      this.TemplateStore.createTemplate(this.template)
        .then(({ data }) => {
          const notificationInfos = {
            msg: data.message,
            status: "success",
            show: true,
          };

          this.$emit("notificate", notificationInfos);
          this.$emit("closeModal");
          this.$emit("refreshList");
        })
        .catch((error) => {
          const notificationInfos = {
            msg: error,
            status: "error",
            show: false,
          };

          this.$emit("notificate", notificationInfos);
          this.$emit("closeModal");
          this.$emit("refreshList");
        });
    },
  },
  mounted() {
    this.$refs.templateName.focus();
  },
};
</script>
