<template>
  <section class="sections__default">
    <Header>
      <h1 class="header__title">{{ $t("logo") }}</h1>
    </Header>
    <div class="px-5 max-w-2xl w-full mx-auto">
      <form
        id="handleLogo"
        @submit.prevent="handleLogo"
        :validation-schema="logoSchema"
        class="hidden"
      ></form>
      <div class="flex md:hidden items-end gap-5">
        <label class="w-full block" for="ip">
          <span class="text-sm text-zinc-500 dark:text-zinc-400">
            {{ $t("page_title_url") }}
          </span>
          <input
            class="dark:bg-zinc-900 dark:text-zinc-400 w-full text-base py-1.5 px-3 bg-zinc-200 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 outline-none focus:ring-zinc-500 rounded-md text-zinc-700"
            type="text"
          />
        </label>
      </div>
      <div class="flex flex-col md:flex-row items-start gap-5 mt-8">
        <div class="max-w-xs w-full">
          <div
            class="p-5 rounded-lg bg-white dark:bg-zinc-700 w-full h-full shadow-sm shadow-zinc-300 dark:shadow-zinc-900"
          >
            <p class="main__title mb-5">{{ $t("company_favicon") }}</p>
            <div
              class="bg-zinc-200 dark:bg-zinc-600 w-full h-32 rounded-lg flex items-center justify-center overflow-hidden object-contain"
            >
              <span v-if="hiddenFavIconImage" class="text-zinc-500 text-lg">
                max. 80 x 80
              </span>
              <img
                class="w-full h-16 object-contain"
                :class="[hiddenFavIconImage ? 'hidden' : 'block']"
                ref="previewFavIconImage"
              />
            </div>
            <div class="relative w-full mt-3">
              <input
                @change="uploadFavIconFile"
                class="peer cursor-pointer absolute block inset-0 opacity-0"
                type="file"
                ref="favIconFile"
              />
              <button
                class="bg-sky-500/60 peer-hover:bg-sky-500 shadow-sm shadow-sky-300 dark:shadow-sky-500 text-zinc-50 rounded-md p-2 w-full flex gap-2 text-sm justify-center items-center"
              >
                <UploadIcon class="text-white w-5 h-5" />
                <span class="cursor-pointer">{{ $t("upload_favicon") }}</span>
              </button>
            </div>
          </div>
        </div>

        <div class="max-w-xs w-full">
          <div
            class="rounded-lg bg-white dark:bg-zinc-700 w-full max-h-96 h-full p-5 shadow-sm shadow-zinc-300 dark:shadow-zinc-900"
          >
            <p class="main__title mb-5">{{ $t("company_logo") }}</p>
            <div
              class="bg-zinc-200 dark:bg-zinc-600 w-full h-32 rounded-lg flex items-center justify-center overflow-hidden object-contain"
            >
              <span v-if="hiddenLogoImage" class="text-zinc-500 text-lg">
                max. 300 x 150
              </span>
              <img
                class="w-full h-32"
                ref="previewLogoImage"
              />
            </div>
            <div class="relative mt-3">
              <input
                @change="uploadLogoFile"
                class="peer cursor-pointer absolute block inset-0 opacity-0"
                type="file"
                name="companyLogo"
                ref="logoFile"
              />
              <button
                class="bg-sky-500/60 peer-hover:bg-sky-500 shadow-sm shadow-sky-300 dark:shadow-sky-500 text-zinc-50 rounded-md p-2 w-full flex gap-2 text-sm justify-center items-center"
              >
                <UploadIcon class="text-white w-5 h-5" />
                <span class="cursor-pointer">{{
                  $t("upload_company_logo")
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden md:flex items-end gap-5 mt-7">
        <label class="w-full block relative" for="ip">
          <span class="text-sm text-zinc-500 dark:text-zinc-400">
            {{ $t("page_title_url") }}
          </span>
          <input
            class="dark:bg-zinc-900 dark:text-zinc-400 w-full text-base py-1.5 px-3 bg-zinc-200 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 outline-none focus:ring-zinc-500 rounded-md text-zinc-700"
            type="text"
            :class="[
              titleError
                ? ' focus:border-red-500'
                : ' focus:border-emerald-500',
            ]"
            v-model="title"
          />
          <span class="text-red-500 absolute top-0 right-0 text-xs">
            {{ titleError }}
          </span>
        </label>
      </div>
      <div class="flex justify-center mt-5">
        <SaveCompanyImages
          form="handleLogo"
          class="w-full md:w-60 py-1.5"
          :saveText="$t('save')"
        />
      </div>
    </div>
  </section>
  <Teleport to="body">
    <Transition name="notification">
      <Notification
        v-if="notificationState.show"
        :msg="notificationState.msg"
        :status="notificationState.status"
      />
    </Transition>
  </Teleport>
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import Header from "../../../components/header/TheHeader.vue";
import SaveCompanyImages from "../../../components/buttons/TheSaver.vue";
import Notification from "../../../components/notification/Notification.vue";
import { UploadIcon } from "@heroicons/vue/outline";
import { useForm, useField } from "vee-validate";
import { useLogoStore } from "../../../../store/LogoStore";
import { onMounted } from "vue";
import i18n from "../../../../i18n";

export let user_id = JSON.parse(localStorage.getItem("auth_payload"));

export default {
  components: {
    Header,
    SaveCompanyImages,
    UploadIcon,
    Notification,
  },
  setup() {
    const LogoStore = useLogoStore();
    const logoInfo = ref(null);

    const user = JSON.parse(localStorage.getItem("user"));

    let companyLogoImg = ref("");
    let companyFavicon = ref("");

    let logoImage = ref(null);
    const logoFile = ref(null);
    const previewLogoImage = ref(null);
    let hiddenLogoImage = ref(true);

    let favIconImage = ref(null);
    const favIconFile = ref(null);
    const previewFavIconImage = ref(null);
    let hiddenFavIconImage = ref(true);

    function uploadLogoFile() {
      logoImage.value = logoFile.value.files[0];
      previewLogoImage.value.src = URL.createObjectURL(logoImage.value);
      if (previewLogoImage.src !== "") hiddenLogoImage.value = false;
    }

    function uploadFavIconFile() {
      favIconImage.value = favIconFile.value.files[0];
      previewFavIconImage.value.src = URL.createObjectURL(favIconImage.value);
      if (previewFavIconImage.src !== "") hiddenFavIconImage.value = false;
    }

    async function submitFile() {
      const formData = new FormData();

      formData.append("logo", logoImage.value);
      formData.append("favicon", favIconImage.value);
      formData.append("_method", "PUT");

      return LogoStore.uploadImages(logoInfo.value[0].id, formData);
    }

    const notificationState = reactive({
      msg: "",
      status: "",
      show: false,
    });

    const logoSchema = {
      title(value) {
        if (!value) {
          return "campo obrigatório";
        }

        if (value.lenght < 4) {
          return "minímo 4 caracteres";
        }

        return true;
      },
    };

    // Create a form context with the validation schema
    const { handleSubmit } = useForm({
      validationSchema: logoSchema,
    });

    // No need to define rules for fields
    const { value: title, errorMessage: titleError } = useField("title");

    function onInvalidSubmit() {
      const notificationInfos = {
        msg: i18n.global.t('check_fields_and_try_again'),
        status: "error",
        show: true,
      };

      handleNotification(notificationInfos);
    }

    function handleNotification(notificationInfos) {
      const { msg, status, show } = notificationInfos;

      notificationState.msg = msg;
      notificationState.status = status;
      notificationState.show = show;

      setTimeout(() => {
        notificationState.msg = "";
        notificationState.status = "";
        notificationState.show = false;
      }, 2500);
    }


    async function getTitle() {
      return LogoStore.getTitle().then(({ data }) => {
                var result = data.data.filter(function(item) {
            return item.id_usuario == user_id.user_id;
        });

        logoInfo.value = result;
        title.value = logoInfo.value[0].title;
      });
    }

    // async function getTitle() {
    //   return LogoStore.getTitle().then(({ data }) => {
    //     logoInfo.value = data.data[0];
    //     title.value = logoInfo.value.title;
    //   });
    // }

    async function getImages() {
      return LogoStore.getImages(logoInfo.value[0].id).then((response) => {

       let logoImg = new File([response[0].data], previewLogoImage.value);

        previewLogoImage.value.src = URL.createObjectURL(logoImg);

        if (previewLogoImage.src !== "") hiddenLogoImage.value = false;
        let faviconImg = new File([response[1].data],previewFavIconImage.value);

        previewFavIconImage.value.src = URL.createObjectURL(faviconImg);

        if (previewFavIconImage.src !== "") hiddenFavIconImage.value = false;
        logoImage.value = logoImg;
        favIconImage.value = faviconImg;
      });
    }

//    async function getImages() {
//      return LogoStore.getImages(logoInfo.value[0].id).then((response) => {

//        let logoImg = new File([response.data], previewLogoImage.value);

//        previewLogoImage.value.src = URL.createObjectURL(logoImg);
//        previewImage.value.src = URL.createObjectURL(img);
//         })
//        .catch((error) => console.log(error));

//    }


    const handleLogo = handleSubmit((values) => {
      let id = logoInfo.value[0].id_usuario;
      let title = values.title;
      LogoStore.editCompanyTitle(title, id).then(({ data }) => {
        const notificationInfos = {
          msg: data.message,
          status: "success",
          show: true,
        };

        handleNotification(notificationInfos);
        getTitle().then(() => {
          getImages();
        });

     
        setTimeout(() => {
          window.location.reload();
        }, "1000");

      });

      if (logoImage.value || favIconImage.value) {
        submitFile()
          .then(({ data }) => data)
          .catch((error) => error);
      }
    }, onInvalidSubmit);

    onMounted(() => {
      getTitle().then(() => {
        //getImageis();
if (logoInfo.value[0].id && logoInfo.value[0].id > 0) {
          getImages(logoInfo.value[0].id);
        }
      });
    });
    return {
      companyLogoImg,
      notificationState,
      companyFavicon,
      logoSchema,
      title,
      titleError,
      handleLogo,
      logoFile,
      uploadLogoFile,
      uploadFavIconFile,
      previewLogoImage,
      previewFavIconImage,
      hiddenLogoImage,
      hiddenFavIconImage,
      favIconFile,
    };
  },
};
</script>
