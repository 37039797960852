<template>
  <div class="permission__checkbox--area min-w-max relative">
    <input
      class="hidden peer"
      :form="formRef"
      :id="productID"
      :name="inputName"
      :value="productID"
      type="checkbox"
      :checked="hasPermission ?? false"
    />
    <CheckIcon class="checked__icon w-5 h-5 transition-all" />
    <label
      :for="productID"
      class="permission__label pl-6 py-1 transition-all peer-checked:checked__label"
    >
      <span class="ml-2">{{ theName }}</span>
    </label>
  </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";
export default {
  components: { CheckIcon },
  props: [
    "productID",
    "inputName",
    "theName",
    "modelArray",
    "formRef",
    "hasPermission",
  ],
};
</script>
