<template>
  <div
    class="2xl:max-h-[80vh] overflow-x-auto 2xl:overflow-x-hidden overflow-y-auto sidebar"
  >
    <table class="table-fixed lg:table-auto w-full text-sm sticky top-0">
      <thead class="sticky top-0">
        <tr class="bg-zinc-200 dark:bg-zinc-700">
          <th
            class="w-32 lg:w-1/6 font-semibold uppercase text-xs text-zinc-600 dark:text-zinc-300 p-3 rounded-tl-lg"
          >
            {{ $t("name") }}
          </th>
          <th
            class="w-60 font-semibold uppercase text-xs text-zinc-600 dark:text-zinc-300 p-3"
          >
            {{ $t("email") }}
          </th>
          <th
            class="w-80 lg:w-1/6 font-semibold text-center uppercase text-xs text-zinc-600 dark:text-zinc-300 p-3 rounded-tr-lg"
          >
            {{ $t("actions") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(user, index) in pabxUsers"
          :key="user[index]"
          class="even:bg-zinc-200 dark:even:bg-zinc-800 dark:hover:bg-zinc-700 border-b border-zinc-300 dark:border-zinc-700 hover:bg-zinc-300"
        >
          <td
            class="p-3 text-zinc-600 dark:text-zinc-200 truncate w-44 max-w-xs lg:max-w-md 2xl:max-w-lg"
          >
            {{ user.nome }}
          </td>
          <td
            class="p-3 text-zinc-600 dark:text-zinc-200 truncate w-60 max-w-xs lg:max-w-md 2xl:max-w-lg"
          >
            {{ user.login }}
          </td>
          <td class="p-3 lg:w-1/6">
            <div class="flex justify-center gap-5 text-xs">
              <a
                :href="getUrl(user)"
                target="_blank"
                class="bg-sky-600 group hover:bg-sky-700 shadow-sm shadow-sky-600/80 hover:shadow-none border border-transparent text-zinc-50 dark:text-zinc-300/90 flex items-center gap-2 px-2 py-0.5 rounded-md"
              >
                <ExternalLinkIcon class="w-4 h-4" />
                <span>{{ $t("access") }}</span>
              </a>
              <button
                @click="$emit('editUser', user.id)"
                class="edit__button rounded-md shadow-sm shadow-zinc-300 dark:shadow-zinc-900 hover:shadow-none cursor-pointer flex items-center gap-2"
              >
                <PencilIcon class="w-4 h-4" />
                <span>{{ $t("edit") }}</span>
              </button>
              <button
                @click="$emit('deleteUser', user)"
                class="bg-red-600 hover:bg-red-700 shadow-sm shadow-red-600/80 hover:shadow-none text-zinc-50 dark:text-zinc-300/90 flex items-center gap-2 px-2 py-0.5 rounded-md"
              >
                <TrashIcon class="w-4 h-4" />
                <span>{{ $t("remove") }}</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { Link } from "@inertiajs/inertia-vue3";
import {
  ExternalLinkIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/vue/outline";

export default {
  props: ["pabxUsers"],
  components: {
    Link,
    ExternalLinkIcon,
    PencilIcon,
    TrashIcon,
  },
  methods: {
     getUrl(user) {
       const { url } = JSON.parse(localStorage.getItem("user"));

       const base = user.url.split('//');

       return base[0] + '//' + url + base[1];
     }
   },
  emits: ["deleteUser", "editUser"],
};
</script>
