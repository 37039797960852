<template>
  <div :class="[customClasses ? 'w-full' : 'w-72']">
    <Listbox v-model="selectedOption">
      <div class="relative">
        <ListboxButton
          :disabled="isDisabled"
          class="relative border border-zinc-700/20 w-full py-2 pl-3 pr-10 group text-left bg-white rounded-lg shadow-sm cursor-default sm:text-sm dark:border-zinc-700 dark:bg-zinc-900/80 dark:shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 disabled:cursor-not-allowed disabled:opacity-80 disabled:bg-zinc-200 dark:disabled:bg-zinc-700"
        >
          <span class="text-sm truncate text-zinc-500 dark:text-zinc-200">
            {{ selectedOption?.name ?? selectedOption?.nome ?? "Indefinido" }}
          </span>
          <span
            v-if="selectedOption?.ip"
            class="text-sm truncate text-zinc-500 dark:text-zinc-200"
          >
            ({{ selectedOption?.ip }})
          </span>
          <span
            class="absolute inset-y-0 right-0 flex items-center pr-2 lg:cursor-default"
          >
            <SelectorIcon
              class="w-5 h-5 text-zinc-600 dark:text-zinc-100"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="z-40 absolute w-full py-1 mt-1 overflow-auto bg-white dark:bg-zinc-700/95 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="opt in options"
              :key="opt.name"
              :value="opt"
              as="template"
            >
              <li
                :class="[
                  active
                    ? 'text-emerald-900 dark:text-zinc-100 bg-emerald-100 dark:bg-zinc-600/80'
                    : 'text-gray-900 dark:text-zinc-200',
                  'cursor-default select-none relative py-2 pl-10 pr-4',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                >
                  {{ opt.name ?? opt.nome }}
                </span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-emerald-600 dark:text-emerald-500"
                >
                  <CheckIcon class="w-5 h-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script>
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { ref } from "@vue/reactivity";
import { computed, onMounted, watch } from "@vue/runtime-core";

export default {
  name: "Select",
  props: ["selectOptions", "customClasses", "isDisabled", "current"],
  emits: ["changed", "selectChanged"],
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
  },
  setup(props, { emit }) {
    let currentOption = computed(() => props.current ?? 0);
    let options = computed(() => props.selectOptions);
    let selectedOption = ref(options.value[currentOption.value]);
    let currentOptionActive = computed(() => selectedOption.value);

    watch(currentOptionActive, () => {
      emit("selectChanged", currentOptionActive.value);
    });

    watch(options, () => {
      if (options.value[0]) selectedOption.value = options.value[0];
    });

    onMounted(() => {
      emit("selectChanged", currentOptionActive.value);
    });

    return {
      selectedOption,
      currentOptionActive,
      options,
    };
  },
};
</script>
