import { defineStore } from "pinia";
import request, { user } from "../http";

export const useActivePabxReportStore = defineStore(
  "useActivePabxReportStore",
  {
    state: () => {
      return {
        activePabxReports: [],
      };
    },

    actions: {
      async getActivePabxReports() {
        return request.get("/core_company/active");
      },

      async getPabxReportsByReseller(resellerId) {
        return request.get("core_company/active", {
          params: {
            id_empresa: resellerId,
          },
        });
      },
    },
  }
);
