<template>
  <section class="sections__default">
    <Header>
      <h1 class="header__title">{{ $t("auditorium") }}</h1>
    </Header>
    <div
      class="flex flex-col md:flex-row md:max-h-screen md:overflow-hidden border-t border-zinc-200 dark:border-zinc-700"
    >
      <AsideMenu @handle-notification="handleNotification" />

      <div class="w-full p-5">
        <h2
          class="text-lg text-zinc-500 text-center"
          v-if="!auditoriumList || auditoriumList.length <= 0"
        >
          {{ $t("do_a_research_aside") }}
        </h2>
        <AuditionTable v-else>
          <template v-slot:ths>
            <th class="main__table--th rounded-tl-lg w-40">
              {{ $t("date") }}
            </th>
            <th class="main__table--th w-52">{{ $t("user") }}</th>
            <th class="main__table--th w-32">{{ $t("module") }}</th>
            <th class="main__table--th w-32">{{ $t("type") }}</th>
            <th class="main__table--th rounded-tr-lg w-60">
              {{ $t("details") }}
            </th>
          </template>
          <template v-slot:trs>
            <tr
              v-for="(audit, index) in auditoriumList"
              :key="index"
              class="dashboard__tbody--tr"
            >
              <td class="main__table--td">{{ audit.data }}</td>
              <td class="main__table--td truncate">
                {{ audit.usuario }}
              </td>
              <td class="main__table--td truncate">{{ audit.modulo }}</td>
              <td
                class="main__table--td truncate font-bold"
                :class="[
                  { 'text-slate-800': audit.tipo === 'Update' },
                  { 'text-sky-600': audit.tipo === 'Insert' },
                  { 'text-red-600': audit.tipo === 'Delete' },
                ]"
              >
                {{ audit.tipo }}
              </td>
              <td class="main__table--td truncate">
                {{ audit.detalhes }}
              </td>
            </tr>
          </template>
        </AuditionTable>
      </div>
    </div>
  </section>

  <Teleport to="body">
    <Transition name="notification">
      <Notification
        v-if="notificationState.show"
        :msg="notificationState.msg"
        :status="notificationState.status"
      />
    </Transition>
  </Teleport>
</template>

<script>
import Header from "../../../components/header/TheHeader.vue";
import AuditionTable from "../../../components/table/MainTable.vue";
import AsideMenu from "./AsideMenu.vue";
import { useAuditoriumStore } from "../../../../store/AuditoriumStore";
import { computed, reactive } from "@vue/reactivity";
import Notification from "../../../components/notification/Notification.vue";

export default {
  components: {
    Header,
    AuditionTable,
    AsideMenu,
    Notification,
  },
  setup() {
    const AuditoriumStore = useAuditoriumStore();
    const auditoriumList = computed(() => AuditoriumStore.auditoriumList);

    let notificationState = reactive({
      msg: "",
      status: "",
      show: false,
    });

    function handleNotification(infos) {
      const { msg, status, show } = infos;

      notificationState.msg = msg;
      notificationState.status = status;
      notificationState.show = show;

      setTimeout(() => {
        notificationState.msg = "";
        notificationState.status = "";
        notificationState.show = false;
      }, 2500);
    }
    return { auditoriumList, handleNotification, notificationState };
  },
};
</script>
