<template>
  <section
    class="z-[200] hidden lg:block w-full max-w-[250px] h-screen text-left z-50 text-zinc-50"
  >
    <div
      class="border-r shadow-none border-zinc-700/50 bg-[#212329] fixed left-0 top-0 max-w-[250px] w-full h-screen z-30"
    >
      <div
        class="h-full flex justify-between flex-col overflow-y-auto overflow-x-hidden sidebar"
      >
        <!--<div class="p-4 relative hidden">
          <div class="w-40 h-11 pl-3">
            <img
              class="max-w-full max-h-full"
              alt="{{ $t('logo') }}"
            />
          </div>
        </div>-->

        <div class="p-4 relative">
          <div class="w-40 pl-3" :style="{ height: '70px' }">
          <img
            class="max-w-full max-h-full m-auto"
            :src="logoImg || null"
          />
        </div>
        </div>

        <div class="flex items-center gap-x-2 p-4 relative">
          <h2 class="text-sm px-2 font-medium flex flex-col">
            <span class="text-xs opacity-80">IP: {{ clientIP }}</span>
          </h2>
          <button
            @click="toggleDarkMode"
            class="absolute top-2.5 right-4 hover:bg-slate-700/80 p-1 rounded-md"
          >
            <SunIcon
              v-if="menuDark"
              class="w-5 h-5 animate__animated animate__fadeInRight animate__faster"
            />
            <MoonIcon
              v-else
              class="w-5 h-5 animate__animated animate__fadeInRight animate__faster"
            />
          </button>
        </div>

        <nav class="flex-1 border-y px-2 border-neutral-600/50">
          <ul>
            <li class="my-4">
              <Link
                class="flex items-center py-2 px-3 rounded-md hover:bg-neutral-600 transition-all"
                :class="[
                  { 'bg-red-600/60': currentRoute === 'pabx.pabx.all' },
                  { 'bg-red-600/60': currentRoute === 'pabx.pabx.edit' },
                ]"
                :href="$route('pabx.pabx.all')"
              >
                <svg
                  class="w-5 h-5 mr-3"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    d="M16 3h1v5h-1zm-1 2h-2V4h2V3h-3v3h2v1h-2v1h3zm3-2v5h1V6h2V3h-3zm2 2h-1V4h1v1zm0 10.5c-1.25 0-2.45-.2-3.57-.57-.1-.03-.21-.05-.31-.05-.26 0-.51.1-.7.29l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.27-.26.35-.65.24-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM5.03 5h1.5c.07.88.22 1.75.46 2.59L5.79 8.8c-.41-1.21-.67-2.48-.76-3.8zM19 18.97c-1.32-.09-2.59-.35-3.8-.75l1.2-1.2c.85.24 1.71.39 2.59.45v1.5z"
                  />
                </svg>
                <span class="text-sm font-medium">
                  {{ $t("virtual_pabx") }}
                </span>
              </Link>
            </li>

            <li class="my-4">
              <Pabx :isOpen="isOpen" />
            </li>

            <li class="my-4">
              <Management :isOpen="isOpen" />
            </li>

            <li class="my-4">
              <Reports :isOpen="isOpen" />
            </li>
          </ul>
        </nav>

        <div class="my-4 mx-2">
          <button
            @click="logout"
            class="px-4 py-2 w-full group opacity-60 hover:opacity-100 first-letter:text-inherit h-full cursor-pointer flex items-center rounded-md hover:bg-neutral-600/10 dark:hover:bg-neutral-400/30 transition-all"
            type="submit"
          >
            <svg
              class="w-4 h-5 mr-3 opacity-60 group-hover:opacity-100"
              viewBox="0 0 18 20"
              fill="currentColor"
            >
              <path
                d="M13 15V12H6V8H13V5L18 10L13 15ZM11 0C11.5304 0 12.0391 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2V4H11V2H2V18H11V16H13V18C13 18.5304 12.7893 19.0391 12.4142 19.4142C12.0391 19.7893 11.5304 20 11 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H11Z"
              />
            </svg>
            <span class="text-sm">{{ $t("exit") }}</span>
          </button>
        </div>
      </div>
    </div>
  </section>
  <MobileMenu />
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import { inject, onMounted, ref } from "@vue/runtime-core";
import MobileMenu from "./mobile/Index.vue";
import Pabx from "./dropdown/Pabx.vue";
import Management from "./dropdown/Management.vue";
import Reports from "./dropdown/Reports.vue";
import { SunIcon, MoonIcon } from "@heroicons/vue/outline";

import {useLogoStore} from "../../../store/LogoStore";

export default {
  name: "Menu",
  components: {
    Link,
    MobileMenu,
    Pabx,
    Management,
    Reports,
    SunIcon,
    MoonIcon,
  },
  data() {
    return {
      menuDark: false,
      isOpen: false,
      clientIP: ''
    };
  },
  setup() {
    const LogoStore = useLogoStore();
    const logo = ref(null);
    const logoImg = ref(null);

    const toggleDarkClass = inject("toggleDark");
    let currentRoute = inject("currentRoute");

    let currentIp = ref("");

    onMounted(async () => {
      await getImagesLogo(logoImg);
    });

    async function getImagesLogo(logoImg) {
      try {
        const response = await LogoStore.getPcpLogoImage(1);
        const blob = new Blob([response.data], { type: "image/jpeg" });
        const logoUrl = URL.createObjectURL(blob);
        logoImg.value = logoUrl ? logoUrl : null;
      } catch (error) {
        console.error(error);
      }
    }

    return {
      toggleDarkClass,
      currentRoute,
      currentIp,
      logoImg,
    };
  },
  methods: {
    toggleDarkMode() {
      this.menuDark = !this.menuDark;
      this.toggleDarkClass();
    },

    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      this.$inertia.get("/login");
    },
  },
  mounted() {
    this.clientIP = this.$page.props.clientIP
  }
};
</script>

