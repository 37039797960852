<template>
  <section class="sections__default">
    <Header>
      <h1 class="header__title">{{ $t("active_pabx") }}</h1>
    </Header>
    <div class="px-5">
      <div
        class="w-full flex flex-col md:flex-row items-end justify-between mb-5"
      >
        <div class="flex flex-col md:flex-row md:items-center gap-5">
          <button
            class="block md:hidden w-full text-sm text-white dark:text-zinc-300 rounded-md shadow-sm shadow-indigo-500 py-2 px-2 bg-indigo-500 dark:bg-indigo-600/90 hover:bg-indigo-600"
          >
            {{ $t("search") }}
          </button>
          <button
            @click="downloadCSV"
            class="w-max ml-auto mt-10 md:mt-0 md:ml-0 bg-slate-600 flex items-center gap-2 dark:bg-slate-700 text-white dark:text-zinc-300 hover:bg-slate-500 dark:hover:bg-slate-500 py-1.5 px-2 rounded-md shadow-sm shadow-slate-500 text-sm"
          >
            <DownloadIcon class="w-5 h-5" />
            <span>{{ $t("download_csv") }}</span>
          </button>
        </div>
      </div>
      <h2
        v-if="!activePabxReports || activePabxReports.length <= 0"
        class="text-lg text-center text-zinc-300 dark:text-zinc-600"
      >
        {{ $t("any_data_found") }}
      </h2>
      <ActivePabxTable v-else>
        <template v-slot:ths>
          <th class="main__table--th w-31 text-center">
            {{ $t("name") }}
          </th>
          <th class="main__table--th w-31 text-center">
            {{ $t("branchs") }}
          </th>
          <th class="main__table--th w-31 text-center">
            {{ $t("trunks") }}
          </th>
          <th class="main__table--th w-31 text-center rounded-tr-lg">
            {{ $t("users") }}
          </th>
        </template>
        <template v-slot:trs>
          <tr
            v-for="(report, index) in activePabxReports"
            :key="index"
            class="dashboard__tbody--tr"
          >
            <td class="main__table--td text-center">
              {{ report.nome}}
            </td>
            <td class="main__table--td text-center">
              {{ report.ramais }}
            </td>
            <td class="main__table--td text-center">
              {{ report.troncos }}
            </td>
            <td class="main__table--td text-center">
              {{ report.usuarios }}
            </td>
          </tr>
        </template>
      </ActivePabxTable>
    </div>
  </section>
</template>

<script>
import Header from "../../../components/header/TheHeader.vue";
import Select from "../../../components/select/Index.vue";
import ActivePabxTable from "../../../components/table/MainTable.vue";
import SearchActivePabx from "../../../components/inputs/TheSearcher.vue";
import { usePabxStore } from "../../../../store/PabxStore";
import { computed, reactive } from "@vue/reactivity";
import { onMounted } from "vue";
import { SearchIcon, DownloadIcon } from "@heroicons/vue/outline";
import { useActivePabxReportStore } from "../../../../store/ActivePabxReports";
import { ExportToCsv } from "export-to-csv";

export default {
  components: {
    Header,
    Select,
    ActivePabxTable,
    SearchActivePabx,
    SearchIcon,
    DownloadIcon,
  },
  setup() {
    const ActivePabxReportStore = useActivePabxReportStore();
    const PabxStore = usePabxStore();

    const activePabxReports = computed(
      () => ActivePabxReportStore.activePabxReports
    );
    const pabxList = computed(() => PabxStore.pabxList);

    let dataToPrint = reactive([]);

    function getPabxList() {
      PabxStore.getPabxList().then(({ data }) => {
        PabxStore.pabxList = data.data;
        pabxList.value.unshift({ id: 0, name: "Todos" });
      });
    }

    function getActivePabxReports() {
      ActivePabxReportStore.getActivePabxReports().then(({ data }) => {
        dataToPrint = data.data.map((row) => {
          delete row.revenda;
          return row;
        });

        ActivePabxReportStore.activePabxReports = data.data;
      });
    }

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Relatório PABX ATIVOS',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Relatorio_PABX_ATIVO'
    };

    const csvExporter = new ExportToCsv(options);

    function downloadCSV() {
      csvExporter.generateCsv(dataToPrint);
    }

    onMounted(() => {
      getPabxList();
      getActivePabxReports();
    });

    return {
      pabxList,
      activePabxReports,
      downloadCSV,
    };
  },
};
</script>
