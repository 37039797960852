<template>
  <details
    class="rounded-md"
    :class="{ 'bg-neutral-600/10 dark:bg-neutral-500/30': isOpen }"
    @click="isOpen = !isOpen"
  >
    <summary
      class="cursor-pointer flex items-center py-2 px-3 rounded-md hover:bg-neutral-600/10 dark:hover:bg-neutral-600 transition-all"
    >
      <svg
        class="w-5 h-5 mr-3"
        enable-background="new 0 0 24 24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <g><path d="M0,0h24v24H0V0z" fill="none" /></g>
        <g>
          <g>
            <path
              d="M15,3H5C3.9,3,3.01,3.9,3.01,5L3,19c0,1.1,0.89,2,1.99,2H19c1.1,0,2-0.9,2-2V9L15,3z M5,19V5h9v5h5v9H5z M9,8 c0,0.55-0.45,1-1,1S7,8.55,7,8s0.45-1,1-1S9,7.45,9,8z M9,12c0,0.55-0.45,1-1,1s-1-0.45-1-1s0.45-1,1-1S9,11.45,9,12z M9,16 c0,0.55-0.45,1-1,1s-1-0.45-1-1s0.45-1,1-1S9,15.45,9,16z"
            />
          </g>
        </g>
      </svg>
      <span class="text-sm">{{ $t("reports") }}</span>
    </summary>
    <ul>
      <li>
        <Link
          :href="$route('reports.active-pabx')"
          class="flex items-center py-2 px-3 group rounded-md hover:bg-neutral-600/10 dark:hover:bg-neutral-200/10 transition-all"
        >
          <span
            class="text-sm relative pl-8 before:absolute before:content-[''] before:left-1.5 before:top-1.5 before:w-1.5 before:h-1.5 before:rounded-full group-hover:before:bg-red-600"
            :class="[
              currentRoute === 'reports.active-pabx'
                ? 'before:bg-red-600 dark:before:bg-red-600'
                : 'before:bg-zinc-500/50 dark:before:bg-zinc-300/80',
            ]"
          >
            {{ $t("active_pabx") }}
          </span>
        </Link>
      </li>
    </ul>
  </details>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import { inject, ref, watch } from "@vue/runtime-core";

export default {
  props: ["isOpen"],
  components: {
    Link,
  },
  setup() {
    let currentRoute = inject("currentRoute");
    let isRouteActive = ref(false);
    function checkRoute(route) {
      if (
        route === "pabx.realtime" ||
        route === "pabx.realtime" ||
        route === "pabx.pabx.resellers" ||
        route === "pabx.pabx.plans" ||
        route === "pabx.pabx.template"
      ) {
        return true;
      }

      return false;
    }
    watch(
      currentRoute,
      () => (isRouteActive.value = checkRoute(currentRoute.value))
    );
    return {
      currentRoute,
      isRouteActive,
    };
  },
};
</script>
