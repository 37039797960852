<template>
  <section class="bg-neutral-900 flex justify-center min-h-screen h-full miw-w-screen w-full">
    <div class="max-w-md w-full h-max mt-16 lg:mt-24 2xl:mt-32 bg-transparent p-5 shadow-2xl rounded-2xl shadow-zinc-900">
      <div class="my-10">
        <img class="w-32 mx-auto mb-10" :src="$asset('images/logo-x.png')" alt="logo on login">
        <h2 class="text-zinc-200 text-lg font-medium text-center">{{ $t('time_to_define_your_new_password') }}</h2>
      </div>
      <form action="">
        <div class="my-5">
          <label class="relative text-zinc-200" for="user">
            <input class="input__login pl-10" id="user" type="text" :placeholder="$t('your_new_password')" />
            <svg class="w-5 h-5 absolute left-2 top-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"/></svg>
          </label>
        </div>

        <div class="my-10">
          <label class="relative text-zinc-200" for="password">
            <input class="input__login pl-10" id="password" type="text" :placeholder="$t('confirm_new_password')" />
            <svg class="w-5 h-5 absolute left-2 top-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><g fill="none"><path d="M0 0h24v24H0V0z"/><path d="M0 0h24v24H0V0z" opacity=".87"/></g><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"/></svg>
          </label>
        </div>
        <LoginButton :text="$t('conclude')" />
      </form>
    </div>
  </section>  
</template>
<script setup>
import { Link } from '@inertiajs/inertia-vue3';
import LoginButton from '../../components/buttons/LoginButton.vue';
import { inject, onMounted, onUnmounted } from '@vue/runtime-core';

const hideSidebar = inject('hideSidebar');
const showSidebar = inject('showSidebar');

onMounted(() => hideSidebar())
onUnmounted(() => showSidebar())
</script>