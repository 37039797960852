<template>
  <section
    class="bg-neutral-900 flex justify-center min-h-screen h-full miw-w-screen w-full"
  >
    <div
      class="max-w-md w-full h-max mt-16 lg:mt-24 2xl:mt-32 bg-transparent p-5 shadow-2xl rounded-2xl shadow-zinc-900"
    >
      <div class="my-10">
        <img
          class="w-32 mx-auto mb-10 hidden"
          :src="$asset('images/logo-x.png')"
          alt="logo on login"
        />
        <h2 class="text-zinc-200 text-lg font-medium text-center">
          {{ $t("hello_log_in_to_proceed") }}
        </h2>
      </div>
      <form @submit.prevent="loginUser" :validation-schema="loginSchema">
        <div class="my-5 relative">
          <label class="relative text-zinc-200" for="user">
            <input
              class="input__login pl-10 border border-zinc-700/40"
              id="user"
              type="text"
              :placeholder="$t('inform_your_email')"
              v-model="login"
            />
            <svg
              class="w-5 h-5 absolute left-2 top-0"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
              />
            </svg>
          </label>
          <span class="text-xs text-red-400 font-bold absolute -top-5 right-0">
            {{ loginError }}
          </span>
        </div>

        <div class="mb-10 relative">
          <label class="relative text-zinc-200" for="password">
            <input
              class="input__login pl-10 border border-zinc-700/40"
              id="password"
              type="password"
              :placeholder="$t('your_password')"
              autocomplete="new-password"
              v-model="password"
            />
            <svg
              class="w-5 h-5 absolute left-2 top-0"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <g fill="none">
                <path d="M0 0h24v24H0V0z" />
                <path d="M0 0h24v24H0V0z" opacity=".87" />
              </g>
              <path
                d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
              />
            </svg>
          </label>
          <span class="text-xs text-red-400 font-bold absolute -top-5 right-0">
            {{ passwordError }}
          </span>
        </div>

        <div>
          <LoginButton :text="$t('log_in')" />
          <!-- <div class="text-right">
            <Link
              :href="$route('password.recovery')"
              class="text-zinc-200 inline-block text-sm my-5 font-medium hover:text-red-500"
              >{{ $t("forgot_password") }}</Link
            >
          </div> -->
        </div>
      </form>
    </div>
  </section>
  <Teleport to="body">
    <Transition name="notification">
      <Notification
        v-if="notificationState.show"
        :msg="notificationState.msg"
        :status="notificationState.status"
      />
    </Transition>
  </Teleport>
</template>
<script>
import { Link } from "@inertiajs/inertia-vue3";
import LoginButton from "../../components/buttons/LoginButton.vue";
import { inject, onMounted, onUnmounted } from "@vue/runtime-core";
import { useForm, useField } from "vee-validate";
import Notification from "../../components/notification/Notification.vue";
import { reactive } from "@vue/reactivity";
import { useLoginStore } from "../../../store/LoginStore";
import request from "../../../http/index.js";

export default {
  components: {
    Link,
    LoginButton,
    Notification,
  },
  setup() {
    const LoginStore = useLoginStore();
    const hideSidebar = inject("hideSidebar");
    const showSidebar = inject("showSidebar");

    const notificationState = reactive({
      msg: "",
      status: "",
      show: false,
    });

    function handleNotification(notificationInfos) {
      const { msg, status, show } = notificationInfos;

      notificationState.msg = msg;
      notificationState.status = status;
      notificationState.show = show;

      setTimeout(() => {
        notificationState.msg = "";
        notificationState.status = "";
        notificationState.show = false;
      }, 2000);
    }

    function getPureUrl() {
      return window.location.origin.split('//')[1];
    }

    const loginSchema = {
      login(value) {
        if (!value) {
          return "campo obrigatório";
        }

        return true;
      },

      password(value) {
        if (!value) {
          return "campo obrigatório";
        }

        return true;
      },
    };

    const { handleSubmit } = useForm({
      validationSchema: loginSchema,
    });

    const { value: login, errorMessage: loginError } = useField("login");
    const { value: password, errorMessage: passwordError } =
      useField("password");

    function onInvalidSubmit() {
      const notificationInfos = {
        msg: "Verifique os campos e tente novamente",
        status: "error",
        show: true,
      };

      handleNotification(notificationInfos);
    }

    const loginUser = handleSubmit((values) => {
      let user = {
        username: values.login,
        password: values.password,
        product: 'bs_pcr',
        current_url: getPureUrl()
      };


      LoginStore.login(user)
        .then(({ data }) => {
            user.id = data.user_id;
            user.url = data.url;

          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("access_token", JSON.stringify(data.access_token));
          localStorage.setItem("auth_payload", JSON.stringify(data));

          window.location.href = "/pabx/pabx-all";
        })
        .catch((error) => {
          const notificationInfos = {
            msg: error.response.data.message,
            status: "error",
            show: true,
          };

          handleNotification(notificationInfos);
        });
    }, onInvalidSubmit);

    window.onpageshow = function (e) {
      if (e.persisted) noBack();
    };

    onMounted(() => {
      hideSidebar();
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      delete request.defaults.headers["Authorization"];

      // return to login page when user click browser back button
      window.history.forward();
      function noBack() {
        window.history.forward();
      }
      noBack();
    });
    onUnmounted(() => showSidebar());
    return {
      loginUser,
      loginSchema,
      login,
      loginError,
      password,
      passwordError,
      notificationState,
      getPureUrl
    };
  },
};
</script>
