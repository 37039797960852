<template>
  <div class="w-full">
    <Listbox v-model="selectedOption">
      <div class="relative mt-1">
        <ListboxButton
          class="relative w-full cursor-default rounded-lg bg-white dark:bg-zinc-900/80 py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        >
          <span class="block text-sm truncate text-zinc-500 dark:text-zinc-200">
            {{ selectedOption.value ?? "Ilimitado" }}
            {{ selectedOption.plural }}
          </span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-zinc-700/95 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="opt in options"
              :key="opt.id"
              :value="opt"
              as="template"
            >
              <li
                :class="[
                  active
                    ? 'text-emerald-900 dark:text-zinc-100 bg-emerald-100 dark:bg-zinc-600/80'
                    : 'text-gray-900 dark:text-zinc-200',
                  'cursor-default select-none relative py-2 pl-10 pr-4',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                >
                  {{ opt.value ?? "Ilimitado" }} {{ opt.plural }}
                </span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-emerald-600 dark:text-emerald-500"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

const props = defineProps({
  options: {
    type: Array,
    default: [],
  },
  current: {
    type: Number,
    default: 1,
  },
});

let currentIndex = ref(0);
const emit = defineEmits(["currentValue"]);

const selectedOption = ref(props.options[currentIndex.value]);

watch(selectedOption, () => emit("currentValue", selectedOption.value));

onMounted(() => {
  currentIndex.value = props.options.findIndex(
    (item) => item.value === props.current
  );

  selectedOption.value = props.options[currentIndex.value];
});
</script>
