<template>
  <section
    class="md:hidden fixed bottom-0 inset-x-0 bg-white h-16 border-t border-zinc-200 shadow-sm shadow-zinc-400 p-5 z-50"
  >
    <nav class="h-full">
      <ul class="flex justify-between items-center h-full">
        <li
          @click="isMenuActive = !isMenuActive"
          :class="[isMenuActive ? 'bg-zinc-100 dark:bg-zinc-700' : '']"
          class="p-2 text-zinc-600 text-xs rounded-md flex flex-col items-center justify-center"
        >
          <svg
            v-if="isMenuActive"
            class="w-5 h-5 mb-1 animate__animated animate__fadeInRight animate__faster"
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M0,0h24v24H0V0z" fill="none" />
            <path
              d="M3,18h13v-2H3V18z M3,13h10v-2H3V13z M3,6v2h13V6H3z M21,15.59L17.42,12L21,8.41L19.59,7l-5,5l5,5L21,15.59z"
            />
          </svg>
          <svg
            v-else
            class="w-5 h-5 mb-1 animate__animated animate__fadeInRight animate__faster"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
          </svg>
          <span>{{ $t("menu") }}</span>
        </li>
      </ul>
    </nav>
  </section>
  <MenuOffcanvas v-if="isMenuActive" :closeOffcanvas="closeOffcanvas" />
</template>

<script setup>
import { Link } from "@inertiajs/inertia-vue3";
import { ref } from "@vue/reactivity";
import MenuOffcanvas from "./offcanvas/MenuOffcanvas.vue";

let isMenuActive = ref(false);
const closeOffcanvas = () => (isMenuActive.value = false);
</script>
