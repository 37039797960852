import { defineStore } from "pinia";
import request from "../http";

const user = JSON.parse(localStorage.getItem("auth_payload"));

export const usePlanStore = defineStore("usePlanStore", {
  state: () => {
    return {
      plans: [],
    };
  },

  actions: {
    async getPlans(name = "") {
      if (!user.user_id) return;

      return request.get("/plan", {
        params: {
          name,
        },
      });
    },

    async createPlan(plan) {
      return request.post("/plan", plan);
    },

    async editPlan(plan) {
      return request.put(`/plan/${plan.id}`, plan);
    },

    async deletePlan(planId) {
      return request.delete(`/plan/${planId}`);
    },
  },
});
