<template>
  <section class="bg-neutral-900 flex justify-center min-h-screen h-full miw-w-screen w-full">
    <div class="max-w-md w-full h-max mt-16 lg:mt-24 2xl:mt-32 bg-transparent p-5 shadow-2xl rounded-2xl shadow-zinc-900">
      <div class="my-10">
        <img class="w-32 mx-auto mb-10" :src="$asset('images/logo-x.png')" alt="logo on login">
        <h2 class="text-zinc-200 text-lg font-medium text-center">{{ $t('inform_your_email_to_recovery_your_password') }}</h2>
      </div>
      <form action="">
        <div class="my-5">
          <label class="relative text-zinc-200" for="user">
            <input class="input__login pl-10" id="user" type="text" :placeholder="$t('inform_your_email')" />
            <svg class="w-5 h-5 absolute left-2 top-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
          </label>
        </div>

        <div>
          <LoginButton :text="$t('reset_password')" />
          <div class="text-right">
            <Link :href="$route('login')" class="text-zinc-200 inline-block text-sm my-5 font-medium hover:text-red-500">{{ $t('login_page') }}</Link>
          </div>
        </div>
      </form>
    </div>
  </section>  
</template>

<script setup>
import { Link } from '@inertiajs/inertia-vue3';
import LoginButton from '../../../components/buttons/LoginButton.vue';
import { inject, onMounted, onUnmounted } from '@vue/runtime-core';

const hideSidebar = inject('hideSidebar');
const showSidebar = inject('showSidebar');

onMounted(() => hideSidebar())
onUnmounted(() => showSidebar())
</script>