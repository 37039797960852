import { defineStore } from "pinia";
import request from "../http";
import admin_request from "../http/admin";
import { user } from "../http";

export const useLogoStore = defineStore("useLogoStore", {
  state: () => {
    return {
      logoInfos: [],
    };
  },

  actions: {
    async getTitle() {
      return admin_request.get("/admin/resale", {
        params: {
          revenda: user.user_id,
          user_id: user.user_id,
        },
      });
    },

    async createCompanyTitle(title) {
      return admin_request.post("/admin/resale", { title });
    },

    async editCompanyTitle(title, id) {
      return admin_request.put(`/admin/resale/${id}`, { title });
    },

    async uploadFile(id, formData) {
      const headers = { "Content-Type": "multipart/form-data" };
      return admin_request.post(`/admin/resale/${id}/file`, formData, {
        headers,
      });
    },

    async getLogoImage(id) {
      const config = { responseType: "blob" };
      return admin_request.get(`/admin/resale/${id}/file/logo`, config);
    },

    async getFavIconImage(id) {
      const config = { responseType: "blob" };
      return admin_request.get(`/admin/resale/${id}/file/favicon`, config);
    },

    async getImages(id) {
      return Promise.all([this.getLogoImage(id), this.getFavIconImage(id)]);
    },

    async uploadImages(id, formData) {
      const headers = { "Content-Type": "multipart/form-data" };
      return admin_request.post(`/admin/resale/${id}/file`, formData, {
        headers,
      });
    },

    async getPcpTitle() {
      return request.get("/web_logo");
    },

    async getPcpLogoImage(id) {
      const config = { responseType: "blob" };
      return request.get(`web_logo/${id}/file/logo`, config);
    },

    async getPcpFavIconImage(id) {
      const config = { responseType: "blob" };
      return request.get(`web_logo/${id}/file/favicon`, config);
    },

    async getPcpImages(id) {
      return Promise.all([this.getLogoImage(id), this.getFavIconImage(id)]);
    },
  },
});
