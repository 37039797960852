<template>
  <div class="flex flex-wrap gap-x-5 gap-y-6 relative">
    <div class="w-full">
      <span
        class="text-xs text-zinc-500 dark:text-zinc-500/80 uppercase mb-2 block"
      >
        {{ $t("template_name") }}
      </span>
      <input
        type="text"
        form="newTemplate"
        class="peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/10 dark:bg-zinc-700/30 px-3 focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-400 placeholder:text-sm placeholder:font-medium ring-1 ring-transparent"
        minlength="3"
        :placeholder="$t('insert_a_name')"
        v-model="name"
        ref="templateName"
      />
    </div>

    <div class="w-full flex justify-end gap-5">
      <CloseButton :closeText="$t('close')" @click="$emit('closeModal')" />
      <SaveButton :saveText="$t('save')" @click="editTemplateName" />
    </div>
  </div>
</template>

<script>
import Select from "../../../../components/select/Index.vue";
import SaveButton from "../../../../components/buttons/TheSaver.vue";
import CloseButton from "../../../../components/buttons/TheCloser.vue";
import { useTemplateStore } from "../../../../../store/TemplateStore.js";

export default {
  name: "PabxForm",
  components: {
    Select,
    SaveButton,
    CloseButton,
  },
  props: ["template"],
  emits: ["closeModal", "notificate", "refreshList"],
  data() {
    return {
      TemplateStore: useTemplateStore(),
      name: "",
      currentServer: null,
    };
  },
  methods: {
    removeNullValues(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );
    },

    editTemplateName() {
      if (!this.name || this.name === "") return;

      this.template.name = this.name;

      this.TemplateStore.editTemplateName(this.template)
        .then(({ data }) => {
          const notificationInfos = {
            msg: data.message,
            status: "success",
            show: true,
          };

          this.$emit("notificate", notificationInfos);
          this.$emit("closeModal");
          this.$emit("refreshList");
        })
        .catch((error) => {
          const notificationInfos = {
            msg: error.response.data.message,
            status: "error",
            show: true,
          };

          this.$emit("notificate", notificationInfos);
          this.$emit("refreshList");
        });
    },
  },
  mounted() {
    this.$refs.templateName.focus();
    this.name = this.template.name;
  },
};
</script>
