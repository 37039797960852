<template>
  <section @click.self="$emit('closeModal')" class="modal__area--fixed z-50">
    <div
      class="w-full modal__area--small animate__animated animate__fadeInDown animate__faster"
    >
      <div class="flex justify-between mb-6">
        <h1 class="text-zinc-500 dark:text-zinc-200 text-xl font-medium">
          {{ $t("confirm_action") }}
        </h1>
        <button class="relative group" @click="$emit('closeModal')">
          <svg
            class="w-5 h-5 text-zinc-600 group-hover:text-zinc-800 dark:group-hover:text-zinc-200"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
            />
          </svg>
          <span class="sr-only">{{ $t("close_modal") }}</span>
        </button>
      </div>
      <p class="text-zinc-500 dark:text-zinc-200 text-sm">
        {{ $t("do_you_really_want_to_delete") }}
      </p>
      <p
        class="mt-2 mb-6 dark:text-slate-700 p-1 rounded-md text-sm dark:bg-zinc-100 inline-block font-semibold"
      >
        {{ plan.nome }}
      </p>
      <div class="flex justify-end gap-3 items-center">
        <CloseModal closeText="Cancelar" @click="$emit('closeModal')" />
        <button
          @click="handleDeleteAction"
          class="text-sm bg-red-700 hover:bg-red-500 rounded-md py-1 px-5 text-white"
        >
          {{ $t("remove") }}
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import CloseModal from "../../../../components/buttons/TheCloser.vue";
import SaveButton from "../../../../components/buttons/TheSaver.vue";
const props = defineProps({
  plan: Object,
});

const emit = defineEmits(["closeModal", "handleDeleteAction"]);

const handleDeleteAction = () => emit("handleDeleteAction", props.plan.id);
</script>

<style></style>
