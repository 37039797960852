<template>
  <section @click.self="$emit('closeModal')" class="modal__area--fixed">
    <div
      class="modal__area animate__animated animate__fadeInDown animate__faster"
    >
      <div class="flex justify-between mb-5">
        <h1 class="text-zinc-500 dark:text-zinc-200 text-xl font-medium">
          {{ $t("edit_template") }}
        </h1>
        <button
          class="relative text-zinc-600 hover:text-zinc-800 dark:hover:text-zinc-200"
          @click="$emit('closeModal')"
        >
          <XIcon class="w-5 h-5" />
          <span class="sr-only">{{ $t("close_modal") }}</span>
        </button>
      </div>

      <Form
        :template="template"
        @notificate="notificate"
        @refresh-list="$emit('refreshList')"
        @close-modal="$emit('closeModal')"
      />
    </div>
  </section>
</template>

<script>
import Form from "./EditForm.vue";
import { useTemplateStore } from "../../../../../store/TemplateStore";
import { XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Form,
    XIcon,
  },
  props: ["template"],
  emits: ["closeModal", "notificate", "refreshList"],
  setup(props, context) {
    const TemplateStore = useTemplateStore();

    function notificate(infos) {
      context.emit("notificate", infos);
    }

    return {
      TemplateStore,
      notificate,
    };
  },
};
</script>
