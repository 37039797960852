<template>
  <div class="flex flex-wrap gap-x-5 gap-y-6">
    <form class="hidden"></form>

    <div class="w-full">
      <span class="text-xs text-zinc-500 uppercase mb-2 block">
        {{ $t("status") }}
      </span>
      <Select
        :customClasses="true"
        :selectOptions="infos.ativo"
        @select-changed="handleChoosenStatus"
      />
    </div>

    <div class="w-full">
      <span class="text-xs text-zinc-500 uppercase mb-2 block">
        {{ $t("name") }}
      </span>
      <input
        type="text"
        class="peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/5 dark:bg-zinc-700/30 px-3 text-sm focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-xs placeholder:font-medium"
        :placeholder="$t('insert_a_name')"
        v-model="infos.name"
        ref="pabxName"
      />
    </div>

    <div class="w-full">
      <span class="text-xs text-zinc-500 uppercase mb-2 block">
        {{ $t("client_code_erp") }}
      </span>
      <input
        type="text"
        class="peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/5 dark:bg-zinc-700/30 px-3 text-sm focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-xs placeholder:font-medium"
        :placeholder="$t('example_1234')"
        maxlength="10"
        v-model="infos.codigo_cli"
      />
    </div>

    <div class="w-full">
      <span class="text-xs text-zinc-500 uppercase mb-2 block">
        {{ $t("sip_server") }}
      </span>
      <Select
        :customClasses="true"
        :isDisabled="true"
        :selectOptions="infos.servidor_sip"
        @select-changed="handleServerChange"
      />
    </div>

    <div class="w-full">
      <span class="text-xs text-zinc-500 uppercase mb-2 block">
        {{ $t("template_pabx") }}
      </span>
      <Select
        :customClasses="true"
        :isDisabled="true"
        :selectOptions="templatesOfServer"
        @select-changed="handleChoosenTemplate"
      />
    </div>

    <div class="w-full">
      <span class="text-xs text-zinc-500 uppercase mb-2 block">
        {{ $t("plan") }}
      </span>
      <Select
        :customClasses="true"
        :selectOptions="infos.plano"
        @select-changed="handleChoosenPlan"
      />
    </div>

    <footer class="w-full flex gap-5 justify-end">
      <CloseButton :closeText="$t('close')" @click="$emit('closeModal')" />
      <SaveButton :saveText="$t('save')" @click="editPabx" />
    </footer>
  </div>
</template>

<script>
import Select from "../../../../components/select/Index.vue";
import SaveButton from "../../../../components/buttons/TheSaver.vue";
import CloseButton from "../../../../components/buttons/TheCloser.vue";
import { TrashIcon } from "@heroicons/vue/solid";
import { usePabxStore } from "../../../../../store/PabxStore";

export default {
  name: "PabxForm",
  components: {
    Select,
    SaveButton,
    CloseButton,
    TrashIcon,
  },
  emits: ["closeModal", "handleNotification", "refreshList"],
  props: ["pabxInfos"],
  data() {
    return {
      pabxStore: usePabxStore(),
      infos: {
        id: null,
        name: "",
        codigo_cli: "",
        id_template: "",
        plano: [{ id: null, name: "Indefinido" }],
        servidor_sip: [{ id: null, name: "Indefinido" }],
        template: [{ id: null, name: "Indefinido" }],
        ativo: [{ id: null, name: "" }],
      },
      currentServer: null,
      currentVersion: null,
      templatesOfServer: [{ id: null, name: "Nenhum template" }],
      templateDisabled: true,
    };
  },
  methods: {
    removeNullValues(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );
    },

    checkIfHasValue(value) {
      if (value[0].length > 0) return value;

      return [{ id: null, name: "Indefinido" }];
    },

    handleServerChange(server) {
      this.currentServer = server.id;
      this.currentVersion = server.version;

      if (!server.version) return;
      this.pabxStore
        .getServerTemplates(this.currentVersion)
        .then(({ data }) => {
          if (data.data.length > 0) {
            this.templateDisabled = false;
            this.templatesOfServer = data.data;
          } else {
            this.templateDisabled = true;
            this.templatesOfServer = [
              { id: null, name: "Nenhum  template selecionado" },
            ];
          }
        });
    },

    handleChoosenTemplate(currentValue) {
      if (currentValue) this.infos.template.id = currentValue.id;
      else this.templatesOfServer = [{ id: null, name: "Indefinido" }];
    },

    handleChoosenPlan(currentValue) {
      this.infos.plano.id = currentValue.id;
    },

    handleChoosenStatus(currentValue) {
      this.infos.ativo.id = currentValue.id;
    },

    createNotification(notification, status = false) {
      const notificationInfos = {
        msg: notification.message,
        status: "error",
        show: false,
      };

      if (status) {
        notificationInfos.msg = notification.message;
        notificationInfos.status = "success";
        notificationInfos.show = true;
      }

      return notificationInfos;
    },

    editPabx() {
      // this.currentServer = this.infos.servidor_sip.id;

      const infosToUpdate = {
        id_template: this.infos.template.id,
        id_planos: this.infos.plano.id,
        name: this.infos.name,
        id_core_servers: this.currentServer,
        cod_cli: this.infos.codigo_cli,
        ativo: this.infos.ativo.id,
      };

      this.pabxStore
        .editPabxInfos(this.currentServer, this.infos.id, infosToUpdate)
        .then(({ data }) => {
          const notificationInfos = this.createNotification(data, true);

          this.$emit("handleNotification", notificationInfos);
          this.$emit("closeModal");
          this.$emit("refreshList");
        })
        .catch((error) => {
          const notificationInfos = this.createNotification(error, false);

          this.$emit("handleNotification", notificationInfos);
          this.$emit("closeModal");
        });
    },

    async getPabxPlan() {
      return this.pabxStore.getServerPlan();
    },

    async getStatus() {
      return this.pabxStore.getStatusToEdit();
    },

    async getServerSip() {
      return this.pabxStore.getServerSip();
    },

    async getServerSipPabx(id) {
      return this.pabxStore.getServerSipPabx(id);
    },

    deletePabx() {
      this.currentServer = this.infos.servidor_sip.id;
      this.pabxStore
        .deletePabx(this.currentServer, this.infos.id)
        .then(({ data }) => {
          const notificationInfos = this.createNotification(data, true);

          this.$emit("handleNotification", notificationInfos);
          this.$emit("closeModal");
          this.$emit("refreshList");
        })
        .catch((error) => {
          const notificationInfos = this.createNotification(error, false);

          this.$emit("handleNotification", notificationInfos);
          this.$emit("closeModal");
        });
    },
  },
  async mounted() {
    this.$refs.pabxName.focus();

    let currentPlanActive = this.pabxInfos.plano;
    let currentServerActive = this.pabxInfos.servidor_sip;
    let currentActiveStatus = this.pabxInfos.ativo;
    this.infos = this.pabxInfos;

    /*
     * get items from endpoint
     * create temporary array, removing the active item
     * insert the active item in the beginning
     */
    let plans = await this.getPabxPlan().then(({ data }) => data.data);
    let plansWithoutCurrent = plans.filter(
      (plan) => plan.id !== currentPlanActive.id
    );

    if (!currentPlanActive || currentPlanActive.length === 0) {
      this.infos.plano = plansWithoutCurrent;
    } else {
      plansWithoutCurrent.unshift(currentPlanActive);
      this.infos.plano = plansWithoutCurrent;
    }

    this.infos.servidor_sip = await this.getServerSip().then(
      ({ data }) => data.data
    );

    let status = await this.getStatus().then(({ data }) => data.data);
    let tempActiveStatus = {};
    let statusWithoutCurrent = status.filter((stat) => {
      if (stat.id !== currentActiveStatus.id) {
        return true;
      }
      tempActiveStatus = stat;
      return false;
    });

    statusWithoutCurrent.unshift(tempActiveStatus);
    this.infos.ativo = statusWithoutCurrent;
  },
};
</script>
