<template>
  <div
    class="flex flex-wrap gap-x-5 gap-y-6"
    tabindex="-1"
    @keyup.esc="$emit('closeModal')"
  >
    <form
      id="newPabx"
      class="hidden absolut opacity-0"
      @submit.prevent="createNewPabxServer"
      :validation-schema="pabxSchema"
    ></form>
    <div class="w-full relative">
      <span class="text-xs text-zinc-500 uppercase mb-2 block">
        {{ $t("name") }}
      </span>
      <input
        type="text"
        form="newPabx"
        class="peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/10 dark:bg-zinc-700/30 px-3 text-sm focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-xs placeholder:font-medium"
        :class="[
          nameError
            ? 'border-red-500 focus:border-red-500'
            : 'focus:border-emerald-500',
        ]"
        :placeholder="$t('insert_a_name')"
        v-model="name"
        ref="pabxName"
      />
      <span class="text-xs text-red-400 font-bold absolute top-0 right-0">
        {{ nameError }}
      </span>
    </div>

    <div class="w-full">
      <span class="text-xs text-zinc-500 uppercase mb-2 block">
        {{ $t("client_code_erp") }}
      </span>
      <input
        type="text"
        class="peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/10 dark:bg-zinc-700/30 px-3 text-sm focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-xs placeholder:font-medium"
        :placeholder="$t('example_1234')"
        maxlength="10"
        v-model="serverInfos.cod_cli"
      />
    </div>

    <div class="w-full">
      <span class="text-xs text-zinc-500 uppercase mb-2 block">
        {{ $t("template_pabx") }}
      </span>
      <Select
        :customClasses="true"
        :isDisabled="templateDisabled"
        :selectOptions="templatesOfServer"
        @selectChanged="handleChoosenTemplate"
      />
    </div>

    <div class="w-full">
      <span class="text-xs text-zinc-500 uppercase mb-2 block">
        {{ $t("plan") }}
      </span>
      <Select
        :customClasses="true"
        :selectOptions="selectPlan"
        @selectChanged="handleChoosenPlan"
      />
    </div>

    <div class="w-full flex gap-5" :class="[inModal ? 'justify-end' : '']">
      <CloseButton
        v-if="inModal"
        :closeText="$t('close')"
        @click="$emit('closeModal')"
      />
      <SaveButton v-if="inModal" :saveText="$t('save')" />
      <div
        v-else
        class="flex justify-end w-full"
        :class="{ 'justify-between': inPabxModal }"
      >
        <button
          v-if="inPabxModal"
          @click="deletePabx"
          class="text-red-600 text-sm flex items-center gap-2 opacity-80 hover:opacity-100"
        >
          <TrashIcon class="w-5 h-5" />
          <span>{{ $t("remove") }}</span>
        </button>
        <div>
          <button
            @click="$emit('closeModal')"
            class="mr-5 text-sm py-1 px-3 bg-zinc-200 dark:bg-zinc-800 dark:hover:bg-zinc-900 text-zinc-500 dark:text-zinc-400 hover:bg-zinc-300 rounded-md shadow-sm shadow-zinc-200 dark:shadow-zinc-900"
          >
            {{ $t("close") }}
          </button>
          <SaveButton form="newPabx" type="submit" :saveText="$t('add')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "../../../../components/select/Index.vue";
import SaveButton from "../../../../components/buttons/TheSaver.vue";
import CloseButton from "../../../../components/buttons/TheCloser.vue";
import { TrashIcon } from "@heroicons/vue/solid";
import { usePabxStore } from "../../../../../store/PabxStore";
import { useForm, useField } from "vee-validate";
import { reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import i18n from "../../../../../i18n";

export default {
  name: "PabxForm",
  components: {
    Select,
    SaveButton,
    CloseButton,
    TrashIcon,
  },
  emits: ["closeModal", "handleNotification", "refreshList"],
  props: ["inModal", "selectSipServer", "selectPlan", "inPabxModal"],
  setup(_, { emit }) {
    let auth = JSON.parse(localStorage.getItem("auth_payload"));
    let serverInfos = reactive({});
    const pabxStore = usePabxStore();
    let currentServer = ref(auth.resale_cs_id);
    const templatesOfServer = ref([{ id: null, name: "Nenhum selecionado" }]);
    const templateDisabled = ref(true);
    const pabxName = ref(null);

    function removeNullValues(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );
    }

    function handleChoosenTemplate(currentValue) {
      serverInfos.id_template = currentValue.id;
    }

    function handleChoosenPlan(currentValue) {
      serverInfos.id_planos = currentValue.id;
    }

    function handleChoosenServer(currentValue) {
      pabxStore
        .getTemplates(currentValue)
        .then(({ data }) => {
          const currentTemplateList = data.data;

          if (currentTemplateList.length > 0) {
            templateDisabled.value = false;
            pabxStore.templateList = currentTemplateList;
            templatesOfServer.value = [{ id: null, name: "Nenhum selecionado" }, ...pabxStore.templateList];
          } else {
            templateDisabled.value = true;
            templatesOfServer.value = [
              { id: null, name: "Nenhum selecionado" },
            ];
            return;
          }

          const notificationInfos = {
            msg: data.message,
            status: "success",
            show: true,
          };

          emit("handleNotification", notificationInfos);
        })
        .catch((error) => {
          const notificationInfos = {
            msg: "Nenhuma versão cadastrada",
            status: "error",
            show: true,
          };

          templateDisabled.value = true;
          templatesOfServer.value = [{ id: null, name: "Nenhum selecionado" }];

          emit("handleNotification", notificationInfos);
        });
    }

    const pabxSchema = {
      name(value) {
        if (!value) {
          return "campo obrigatório";
        }

        if (value.length < 3) {
          return "mínimo de 3 caracteres";
        }

        return true;
      },
    };

    // Create a form context with the validation schema
    const { handleSubmit } = useForm({
      validationSchema: pabxSchema,
    });

    // No need to define rules for fields
    const { value: name, errorMessage: nameError } = useField("name");

    function onInvalidSubmit() {
      const notificationInfos = {
        msg: i18n.global.t('check_fields_and_try_again'),
        status: "error",
        show: true,
      };

      emit("handleNotification", notificationInfos);
    }

    const createNewPabxServer = handleSubmit((values) => {
      let user = JSON.parse(localStorage.getItem("user"));
      let auth = JSON.parse(localStorage.getItem("auth_payload"));

      serverInfos.name = values.name;
      serverInfos.id_usuario_revendedor = user.usu_id;
      serverInfos = removeNullValues(serverInfos);
      serverInfos.id_core_servers = auth.resale_cs_id;

      pabxStore
        .createNewPabx(auth.resale_cs_id, serverInfos)
        .then(({ data }) => {
          const notificationInfos = {
            msg: data.message,
            status: "success",
            show: true,
          };

          emit("handleNotification", notificationInfos);
          emit("closeModal");
          emit("refreshList");
        })
        .catch((error) => {
          const notificationInfos = {
            msg: error,
            status: "error",
            show: false,
          };

          emit("handleNotification", notificationInfos);
          emit("closeModal");
        });
    });

    handleChoosenServer(auth.resale_cs_id);

    onMounted(() => pabxName.value.focus());
    return {
      serverInfos,
      pabxSchema,
      name,
      nameError,
      createNewPabxServer,
      handleChoosenTemplate,
      handleChoosenPlan,
      handleChoosenServer,
      templatesOfServer,
      templateDisabled,
      pabxName,
    };
  },
};
</script>
