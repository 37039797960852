<template>
  <article
    class="text-white flex gap-5 items-center fixed top-2 inset-x-0 lg:inset-auto mx-5 lg:top-[2%] lg:right-[2%] z-50 p-4 rounded-lg"
    :class="[
      status === 'success'
        ? 'bg-emerald-500 shadow-emerald-600'
        : 'bg-red-500 shadow-red-600',
    ]"
  >
    <CheckIcon v-if="status === 'success'" class="w-6 h-6" />
    <XIcon v-if="status === 'error'" class="w-6 h-6" />
    <h1 class="text-sm lg:text-lg">
      {{ msg }}
    </h1>
  </article>
</template>

<script>
import { CheckIcon, XIcon } from "@heroicons/vue/solid";

export default {
  name: "Notification",
  props: ["msg", "status"],
  components: {
    CheckIcon,
    XIcon,
  },
};
</script>
