<template>
  <section @click.self="$emit('closeModal')" class="modal__area--fixed">
    <div
      class="modal__area sm:mt-[2vh] 2xl:max-w-xl w-full animate__animated animate__fadeInDown animate__faster"
    >
      <h1 class="header__title">{{ $t("create_new_plan") }}</h1>
      <form
        id="newPlan"
        class="flex flex-wrap 2xl:grid 2xl:grid-cols-2 gap-8 my-6"
        @submit.prevent="createPlan"
        :validation-schema="planSchema"
      >
        <div class="w-full 2xl:col-span-1 relative">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("name") }}
          </span>
          <input
            type="text"
            class="peer border outline-none border-zinc-200 text-zinc-500 dark:text-zinc-200 dark:border-zinc-700 space-input rounded-md w-full dark:bg-zinc-700/30 px-3 text-sm focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 font-mono placeholder:text-zinc-500 placeholder:text-xs placeholder:font-medium"
            :class="[
              nameError
                ? 'border-red-500 focus:border-red-500'
                : 'border-emerald-500 focus:border-emerald-500',
            ]"
            :placeholder="$t('insert_a_name')"
            ref="planName"
            v-model="name"
          />
          <span class="text-xs text-red-400 font-bold absolute top-0 right-0">
            {{ nameError }}
          </span>
        </div>
        <div class="w-full 2xl:col-span-1 relative">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("what_is_the_limit_of_sip_branchs") }}
          </span>

          <template v-if="useCustomBranchValue">
            <input
              type="number"
              class="peer border outline-none border-zinc-200 text-zinc-500 dark:text-zinc-200 dark:border-zinc-700 space-input rounded-md w-full dark:bg-zinc-700/30 px-3 text-sm focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 font-mono placeholder:text-zinc-500 placeholder:text-xs placeholder:font-medium"
              placeholder="Digite o valor (máx. 5000)"
              v-model.number="customBranchValue"
              @input="restrictInputLength"
              @blur="validateCustomBranchValue"
            />
          </template>
          <template v-else>
            <Select :options="selectBranch" @current-value="selectBranchValue" />
          </template>
          <span v-if="customBranchValueError && useCustomBranchValue" class="text-xs text-red-400 font-bold">
            {{ customBranchValueError }}
          </span>
          <div class="w-full mt-1 text-zinc-500 dark:text-zinc-300 2xl:col-span-1 flex items-center">
            <input
              type="checkbox"
              id="customBranchCheckbox"
              v-model="useCustomBranchValue"
              class="mr-2"
              @change="toggleCustomBranchValue"
            />
            <label class="text-zinc-500" for="customBranchCheckbox">Deseja informar um valor?</label>
          </div>
        </div>
        <div class="w-full 2xl:col-span-1">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("which_is_limit_of_record_sip") }}
          </span>
          <Select
            :options="selectSipRecord"
            @current-value="selectSipRecordValue"
          />
        </div>
        <div class="w-full 2xl:col-span-1">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("how_much_sip_trunks") }}
          </span>
          <Select
            :options="selectSipTrunks"
            @current-value="selectSipTrunksValue"
          />
        </div>
        <div class="w-full 2xl:col-span-1">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("how_much_surveys") }}
          </span>
          <Select :options="selectSurvey" @current-value="selectSurveyValue" />
        </div>
        <div class="w-full 2xl:col-span-1">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("how_much_allowed_uras") }}
          </span>
          <Select :options="selectUra" @current-value="selectUraValue" />
        </div>
      </form>
      <div class="flex gap-5 justify-end items-center">
        <CloseModal :closeText="$t('close')" @click="$emit('closeModal')" />
        <SavePlan form="newPlan" type="submit" :saveText="$t('add')" />
      </div>
    </div>
  </section>
</template>

<script>
import SavePlan from "../../../../components/buttons/TheSaver.vue";
import CloseModal from "../../../../components/buttons/TheCloser.vue";
import Select from "../select/Index.vue";
import { ref, reactive } from "@vue/reactivity";
import { usePlanStore } from "../../../../../store/PlanStore.js";
import { useForm, useField } from "vee-validate";
import { onMounted, watch } from "@vue/runtime-core";
import i18n from "../../../../../i18n";

export default {
  components: {
    SavePlan,
    CloseModal,
    Select,
  },
  emit: ["closeModal", "refreshPlans", "handleNotification"],

  setup(_, { emit }) {
    const PlanStore = usePlanStore();
    const planName = ref(null);

    const selectBranch = ref([]);
    const selectSipRecord = ref([]);
    const selectSipTrunks = ref([]);
    const selectSurvey = ref([]);
    const selectUra = ref([]);

    const useCustomBranchValue = ref(false);
    const customBranchValue = ref(null); 
    const customBranchValueError = ref(null);

    function handleSelectOptions(arrTarget, maxValue, name = [], useSpecificValues = false, unlimited = true, includeZero = false) {
    
      
      if (unlimited) {
        arrTarget.push({ id: 101, value: "Ilimitado" });
      }
      
      if (includeZero) {
        arrTarget.push({ id: 0, value: 0, plural: name[0] ?? "" });
      }
      

      if (useSpecificValues) {
        const specificValues = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
          15, 20, 25, 30, 35, 40, 45, 50,
          55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
          110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
          210, 220, 230, 240, 250, 300, 350, 400, 450, 500,
          550, 600, 650, 700, 750, 800, 900, 1000, 1100, 1200,
          1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000,
          2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000,
          3100, 3200, 3300, 3400, 3500, 3600, 3700, 3800, 3900, 4000,
          4100, 4200, 4300, 4400, 4500, 4600, 4700, 4800, 4900, 5000
        ];


        specificValues.forEach(value => {
          arrTarget.push({
            id: value,
            value: value,
            plural: `${value < 2 ? name[0] ?? "" : name[1] ?? ""}`
          });
        });
      } else {
        for (let j = 1; j <= maxValue; j++) {
          arrTarget.push({
            id: j,
            value: j,
            plural: `${j < 2 ? name[0] ?? "" : name[1] ?? ""}`,
          });
        }
      }
    }

    const selectBranchValue = (currentValue) => {
      newPlan.limit_ramais_sip = currentValue.value;
    };

    const selectSipRecordValue = (currentValue) => {
      newPlan.limit_ramais_gravacao = currentValue.value;
    };
    const selectSipTrunksValue = (currentValue) => {
      newPlan.limit_troncos_sip = currentValue.value;
    };
    const selectSurveyValue = (currentValue) => {
      newPlan.limit_pesquisa_satisfacao = currentValue.value;
    };
    const selectUraValue = (currentValue) => {
      newPlan.limit_ura = currentValue.value;
    };

    const authPayload = JSON.parse(localStorage.getItem("auth_payload"));
    handleSelectOptions(selectBranch.value, authPayload?.limits?.peer_limit ?? 100, ["ramal", "ramais"], true, authPayload?.limits?.peer_limit != null ? false : true);
    handleSelectOptions(selectSipRecord.value, 10, ["ramal", "ramais"], false, true, true);
    handleSelectOptions(selectSipTrunks.value, 10, [
      "tronco SIP",
      "troncos SIP",
    ]);
    handleSelectOptions(selectSurvey.value, 10, ["pesquisa", "pesquisas"]);
    handleSelectOptions(selectUra.value, 10);

    const newPlan = reactive({
      nome: "",
      limit_ramais_sip: "",
      limit_ramais_gravacao: "",
      limit_troncos_sip: "",
      limit_pesquisa_satisfacao: "",
      limit_ura: "",
    });

    function isNameValid() {
      if (!newPlan.nome || newPlan.nome.length < 3) return false;

      return true;
    }

    const planSchema = {
      name(value) {
        if (!value) {
          return "campo obrigatório";
        }

        if (value.length < 3) {
          return "Mínimo de 3 caracteres";
        }

        return true;
      },
    };

    const { handleSubmit } = useForm({
      validationSchema: planSchema,
    });

    const { value: name, errorMessage: nameError } = useField("name");

    function onInvalidSubmit() {
      const notificationInfos = {
        msg: i18n.global.t('check_fields_and_try_again'),
        status: "error",
        show: true,
      };

      emit("handleNotification", notificationInfos);
    }


    const validateCustomBranchValue = () => {
      if (customBranchValue.value === null || customBranchValue.value === undefined || customBranchValue.value === '') {
          customBranchValueError.value = "Este campo é obrigatório";
      } else if (customBranchValue.value <= 0) {
          customBranchValueError.value = "O valor deve ser maior que 0";
      } else if (String(customBranchValue.value).length > 4) {
          customBranchValueError.value = "Máximo de 4 dígitos";
      } else if (customBranchValue.value > 5000) {
          customBranchValueError.value = "O valor máximo permitido é 5000";
      } else {
          customBranchValueError.value = null;
      }
    };

    const restrictInputLength = (event) => {
      const maxLength = 4;
      if (event.target.value.length > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
        customBranchValue.value = Number(event.target.value);
      }
    };

    const createPlan = handleSubmit((values) => {
      const user = JSON.parse(localStorage.getItem("user"));
      newPlan.nome = values.name;
      newPlan.id_usuario_revendedor = user.usu_id;


      validateCustomBranchValue();
      if (customBranchValueError.value) {
        return;
      }

      newPlan.limit_ramais_sip = useCustomBranchValue.value ? (customBranchValue.value ?? 0) : newPlan.limit_ramais_sip;

      PlanStore.createPlan(newPlan).then(({ data }) => {
        const notificationInfos = {
          msg: data.message,
          status: "success",
          show: true,
        };

        emit("handleNotification", notificationInfos);
        emit("refreshPlans");
        emit("closeModal");
      });
    }, onInvalidSubmit);

     const toggleCustomBranchValue = () => {
      if (!useCustomBranchValue.value) {
        customBranchValue.value = null; 
        customBranchValueError.value = null; 
      } else {
        customBranchValue.value = null;
         customBranchValueError.value = null;
      }
    };

    onMounted(() => planName.value.focus());
    return {
      selectBranch,
      selectSipRecord,
      selectSipTrunks,
      selectSurvey,
      selectUra,
      selectBranchValue,
      selectSipRecordValue,
      selectSipTrunksValue,
      selectSurveyValue,
      selectUraValue,
      newPlan,
      isNameValid,
      createPlan,
      name,
      nameError,
      planSchema,
      planName,
      useCustomBranchValue,
      customBranchValue,
      customBranchValueError,
      validateCustomBranchValue,
      restrictInputLength,
       toggleCustomBranchValue
    };
  },
};
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.no-spinners {
  appearance: textfield;
}

.space-input {
  padding-top: 0.44rem;
  padding-bottom: 0.44rem;
}
</style>