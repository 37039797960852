import { defineStore } from "pinia";
import request from "../http";

export const useAuditoriumStore = defineStore("useAuditoriumList", {
  state: () => {
    return {
      auditoriumList: [],
    };
  },

  actions: {
    async getAuditoriumList(searchParams) {
      return request.get("/log_audit", {
        params: searchParams,
      });
    },

    async getSelectUsers() {
      return request.get("/log_audit/user");
    },

    async getModules() {
      return request.get("/log_audit/modules");
    },

    async getTypes() {
      return request.get("/log_audit/type");
    },
  },
});
