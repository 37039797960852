<template>
  <details
    class="rounded-md"
    :class="{ 'bg-zinc-500/20 dark:bg-zinc-600/30': isRouteActive }"
    :open="isRouteActive"
    @click="isOpen = !isOpen"
  >
    <summary
      class="cursor-pointer flex items-center py-2 px-3 rounded-md hover:bg-neutral-600/10 dark:hover:bg-neutral-600 transition-all"
    >
      <svg class="w-5 h-5 mr-3" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"
        />
      </svg>
      <span class="text-sm uppercase">{{ $t("pabx") }}</span>
    </summary>
    <ul>
      <li>
        <Link
          :href="$route('pabx.pabx.plans')"
          class="flex items-center py-2 px-3 group rounded-md hover:bg-neutral-600/10 dark:hover:bg-neutral-200/10 transition-all"
        >
          <span
            class="text-sm relative pl-8 before:absolute before:content-[''] before:left-1.5 before:top-2 my-auto before:w-1.5 before:h-1.5 before:rounded-full group-hover:before:bg-red-600"
            :class="[
              currentRoute === 'pabx.pabx.plans'
                ? 'before:bg-red-600 dark:before:bg-red-600'
                : 'dark:before:bg-zinc-300/80 before:bg-zinc-500/50',
            ]"
          >
            {{ $t("plans") }}
          </span>
        </Link>
      </li>

      <li>
        <Link
          :href="$route('pabx.pabx.template')"
          class="flex items-center py-2 px-3 group rounded-md hover:bg-neutral-600/10 dark:hover:bg-neutral-200/10 transition-all"
        >
          <span
            class="text-sm relative pl-8 before:absolute before:content-[''] before:left-1.5 before:top-2 before:w-1.5 before:h-1.5 before:rounded-full group-hover:before:bg-red-600"
            :class="[
              currentRoute === 'pabx.pabx.template'
                ? 'before:bg-red-600 dark:before:bg-red-600'
                : 'dark:before:bg-zinc-300/80 before:bg-zinc-500/50',
            ]"
          >
            {{ $t("pabx_template") }}
          </span>
        </Link>
      </li>
    </ul>
  </details>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import { inject, ref, watch } from "@vue/runtime-core";

export default {
  props: ["isOpen"],
  components: {
    Link,
  },
  setup() {
    let currentRoute = inject("currentRoute");
    let isRouteActive = ref(false);
    function checkRoute(route) {
      if (
        route === "pabx.realtime" ||
        route === "pabx.realtime" ||
        route === "pabx.pabx.resellers" ||
        route === "pabx.pabx.plans" ||
        route === "pabx.pabx.template"
      ) {
        return true;
      }

      return false;
    }
    watch(
      currentRoute,
      () => (isRouteActive.value = checkRoute(currentRoute.value))
    );
    return {
      currentRoute,
      isRouteActive,
    };
  },
};
</script>
