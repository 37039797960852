<template>
  <section @click.self="$emit('closeModal')" class="modal__area--fixed">
    <div
      class="modal__area animate__animated animate__fadeInDown animate__faster z-[300]"
    >
      <div class="flex justify-between mb-5">
        <h1 class="header__title md:max-w-[240px] 2xl:max-w-[300px] truncate">
          {{ $t("editing") }}
          <span v-once class="text-sky-600">{{ pabxInfos.name }}</span>
        </h1>
        <button class="relative group" @click="$emit('closeModal')">
          <svg
            class="w-5 h-5 text-zinc-600 group-hover:text-zinc-800 dark:group-hover:text-zinc-200"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
            />
          </svg>
          <span class="sr-only">{{ $t("close_modal") }}</span>
        </button>
      </div>
      <EditForm
        :pabxInfos="pabxInfos"
        @handle-notification="handleNotification"
        @close-modal="$emit('closeModal')"
        @refresh-list="$emit('refreshList')"
      />
    </div>
  </section>
</template>
<script>
import EditForm from "./EditForm.vue";

export default {
  name: "EditPabx",
  components: { EditForm },
  emits: ["closeModal", "handleNotification", "refreshList"],
  props: ["selectPlan", "pabxInfos"],
  methods: {
    handleNotification(notificationInfos) {
      this.$emit("handleNotification", notificationInfos);
    },
  },
};
</script>
