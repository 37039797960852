<template>
  <section @click.self="$emit('closeModal')" class="modal__area--fixed">
    <div
      class="modal__area animate__animated animate__fadeInDown animate__faster"
    >
      <div class="flex justify-between mb-5">
        <h1 class="text-zinc-500 dark:text-zinc-200 text-xl font-medium">
          {{ $t("add_template") }}
        </h1>
        <button
          class="relative text-zinc-600 hover:text-zinc-800 dark:hover:text-zinc-200"
          @click="$emit('closeModal')"
        >
          <XIcon class="w-5 h-5" />
          <span class="sr-only">{{ $t("close_modal") }}</span>
        </button>
      </div>

      <Form
        :select-sip-server="selectSipServer"
        @notificate="notificate"
        @refresh-list="$emit('refreshList')"
        @close-modal="$emit('closeModal')"
      />
    </div>
  </section>
</template>

<script>
import Form from "./FormModal.vue";
import { useTemplateStore } from "../../../../../store/TemplateStore";
import { onMounted, ref } from "@vue/runtime-core";
import { XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Form,
    XIcon,
  },
  emits: ["closeModal", "notificate", "refreshList"],
  setup(_, context) {
    const TemplateStore = useTemplateStore();

    let selectSipServer = ref([{ id: 101, name: "Nenhum selecionado" }]);

    function getServers() {
      TemplateStore.getServerSip()
        .then(({ data }) => (selectSipServer.value = data.data))
        .catch((error) => console.log(error));
    }

    function notificate(infos) {
      context.emit("notificate", infos);
    }
    onMounted(() => getServers());

    return {
      TemplateStore,
      getServers,
      selectSipServer,
      notificate,
    };
  },
};
</script>
