require("./bootstrap");

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { InertiaProgress } from "@inertiajs/progress";
import PageLayout from "./Pages/PageLayout";
import { asset } from "@codinglabs/laravel-asset";
import { createPinia } from "pinia";

import "@fontsource/inter";

import i18n from "./i18n";
import "animate.css";

export const baseURL = process.env.MIX_API_URL;
export const basePATH = process.env.MIX_API_PATH;

console.log('app.baseURL', baseURL);
console.log('app.basePATH', basePATH);

InertiaProgress.init();

createInertiaApp({
  resolve: (name) => {
    const page = require(`./Pages/${name}`).default;
    page.layout = page.layout || PageLayout;
    return page;
  },
  setup({ el, App, props, plugin }) {
    createApp({
      render: () => h(App, props),
    })
      .mixin({
        methods: {
          $route: route,
          $asset: asset
        },
      })
      .use(createPinia())
      .use(plugin)
      .use(i18n)
      .mount(el);
  },
});
