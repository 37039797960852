<template>
  <section
    @click.self="$emit('closeModal')"
    class="fixed inset-0 bg-zinc-100/70 dark:bg-zinc-900/70 flex justify-center transition-all z-40"
  >
    <div
      class="max-w-3xl 2xl:max-w-4xl bg-zinc-50 dark:bg-[#212329] border-2 border-zinc-200 dark:border-zinc-800 shadow-md shadow-zinc-300 dark:shadow-zinc-900 p-5 rounded-2xl max-h-[90vh] mt-5 animate__animated animate__fadeInDown animate__faster"
    >
      <h1 class="text-zinc-700 dark:text-zinc-500 text-xl">
        {{ $t("create_new_user") }}
      </h1>
      <div class="flex flex-col flex-1 max-h-full pb-8 2xl:pb-10">
        <div class="flex gap-5 my-10">
          <form
            id="newUserForm"
            class="hidden"
            @submit.prevent="createUser"
            :validation-schema="userSchema"
            ref="userForm"
          ></form>
          <div class="w-56 2xl:w-full relative">
            <span
              class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
            >
              {{ $t("name") }}
            </span>
            <input
              type="text"
              form="newUserForm"
              class="text-xs 2xl:text-sm peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/10 dark:bg-zinc-700/30 px-3 focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-sm placeholder:font-medium"
              :class="[
                nameError
                  ? ' focus:border-red-500'
                  : ' focus:border-emerald-500',
              ]"
              :placeholder="$t('insert_a_name')"
              v-model="name"
              ref="userName"
            />
            <span
              class="text-xs text-red-400 font-bold absolute top-0 right-0 lowercase"
            >
              {{ nameError }}
            </span>
          </div>
          <div class="w-56 2xl:w-full relative">
            <span
              class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
              >{{ $t("email") }}</span
            >
            <input
              type="email"
              form="newUserForm"
              class="text-xs 2xl:text-sm peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/10 dark:bg-zinc-700/30 px-3 focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-sm placeholder:font-medium"
              :class="[
                emailError
                  ? ' focus:border-red-500'
                  : ' focus:border-emerald-500',
              ]"
              :placeholder="$t('business_email')"
              v-model="email"
            />
            <span
              class="text-xs text-red-400 font-bold absolute top-0 right-0 lowercase"
            >
              {{ emailError }}
            </span>
          </div>
          <div class="w-56 2xl:w-full relative">
            <span
              class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
              >{{ $t("password") }}</span
            >
            <input
              type="text"
              form="newUserForm"
              class="text-xs 2xl:text-sm peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/10 dark:bg-zinc-700/30 px-3 focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-sm placeholder:font-medium"
              :class="[
                passwordError
                  ? ' focus:border-red-500'
                  : ' focus:border-emerald-500',
              ]"
              :placeholder="$t('password')"
              v-model="password"
            />
            <span
              class="text-xs text-red-400 font-bold absolute top-0 right-0 lowercase"
            >
              {{ passwordError }}
            </span>
          </div>
        </div>
        <div
          class="w-full relative p-5 border border-zinc-300 dark:border-zinc-700 rounded-md overflow-y-auto max-h-[45%] 2xl:max-h-[60%] sidebar"
        >
          <div class="flex justify-between items-center mb-5">
            <h2 class="text-zinc-500 uppercase text-xs font-semibold">
              {{ $t("permissions") }}
            </h2>
            <label
              for="checkAll"
              class="inline-flex items-center cursor-pointer text-zinc-600 dark:text-zinc-200"
            >
              <div class="relative mr-3">
                <input
                  @change="toggleAllPermissions"
                  type="checkbox"
                  id="checkAll"
                  class="sr-only toggle peer"
                  ref="checkAllInput"
                />
                <div
                  class="block bg-gray-500 peer-checked:bg-emerald-500 w-10 h-6 rounded-full"
                ></div>
                <div
                  class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"
                ></div>
              </div>
              {{ $t("all") }}
            </label>
          </div>
          <div
            class="mb-10"
            v-for="(permission, index) in permissions"
            :key="index"
          >
            <label
              :for="permission.modulos_central"
              class="inline-flex items-center cursor-pointer text-zinc-600 dark:text-zinc-200"
            >
              <div class="relative mr-3">
                <input
                  @change="toggleModulePermissions(permission.modulos_central)"
                  type="checkbox"
                  form="newUserForm"
                  :name="permission.modulos_central"
                  :id="permission.modulos_central"
                  class="sr-only toggle peer"
                />
                <div
                  class="block bg-gray-500 peer-checked:bg-emerald-500 w-10 h-6 rounded-full"
                ></div>
                <div
                  class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"
                ></div>
              </div>
              <span>
                {{ capitalizeString(permission.modulos_central) }}
              </span>
              <span class="text-zinc-500/80 dark:text-zinc-500 ml-2">
                ({{ $t("check_all") }})
              </span>
            </label>
            <div class="mt-3 flex flex-wrap gap-2 sm:gap-5">
              <Checkbox
                v-for="(submodule, index) in permission.opcoes"
                :formRef="'newUserForm'"
                :key="index"
                :inputName="permission.name + '[]'"
                :productID="`${submodule}_${index}`"
                :theName="capitalizeString(submodule).replaceAll('_', ' ')"
              />
            </div>
            <hr class="my-5 border-zinc-300 dark:border-zinc-700" />
          </div>
        </div>
        <footer class="flex items-center justify-end gap-5 mt-5">
          <CloseButton :closeText="$t('close')" @click="$emit('closeModal')" />
          <SaveButton form="newUserForm" :saveText="$t('add')" />
        </footer>
      </div>
    </div>
  </section>
</template>
<script>
import Checkbox from "../buttons/CheckboxPermission.vue";
import CloseButton from "../../../../components/buttons/TheCloser.vue";
import SaveButton from "../../../../components/buttons/TheSaver.vue";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useForm, useField } from "vee-validate";
import { usePabxUserStore } from "../../../../../store/PabxUsersStore";
import i18n from "../../../../../i18n";

export default {
  components: {
    Checkbox,
    CloseButton,
    SaveButton,
  },
  emits: ["closeModal", "handleNotification", "refreshList"],
  setup(_, { emit }) {
    const PabxUserStore = usePabxUserStore();
    const userInfos = ref({});
    let permissions = ref([]);
    const userForm = ref(null);
    const checkAllInput = ref(null);

    const userName = ref(null);

    function capitalizeString(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function getModules() {
      PabxUserStore.getModules()
        .then(({ data }) => {
          permissions.value = data.data;
        })
        .catch((error) => console.log(error));
    }

    function validateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    }

    function toggleAllPermissions() {
      let inputs = Array.from(userForm.value.elements);

      inputs.forEach((input) => {
        if (input.type === "checkbox") {
          input.checked = checkAllInput.value.checked;
        }
      });
    }

    function toggleModulePermissions(module) {
      let inputs = userForm.value.elements;

      let currentModule = null;
      let currentPermissions = null;

      if (module === "sip") {
        currentModule = inputs["sip"];
        currentPermissions = inputs["modulos_sip[]"];
      }

      if (module === "dialplan") {
        currentModule = inputs["dialplan"];
        currentPermissions = inputs["modulos_dialplan[]"];
      }

      if (module === "pabx") {
        currentModule = inputs["pabx"];
        currentPermissions = inputs["modulos_pabx[]"];
      }

      if (module === "gerenciamento") {
        currentModule = inputs["gerenciamento"];
        currentPermissions = inputs["modulos_gerenciamento[]"];
      }

      if (module === "relatorios_persa") {
        currentModule = inputs["relatorios_persa"];
        currentPermissions = inputs["modulos_relatorios_persa[]"];
      }

      if (module === "callcenter") {
        currentModule = inputs["callcenter"];
        currentPermissions = inputs["modulos_callcenter[]"];
      }

      if (module === "discador") {
        currentModule = inputs["discador"];
        currentPermissions = inputs["modulos_discador[]"];
      }

      if (Array.from(currentPermissions).length > 0) {
        Array.from(currentPermissions).forEach((perm) => {
          perm.checked = currentModule.checked;
        });
      } else {
        currentPermissions.checked = currentModule.checked;
      }
    }

    const userSchema = {
      name(value) {
        if (!value) {
          return "campo obrigatório";
        }
        if (value.length < 3) {
          return "minímo 3 caracteres";
        }

        return true;
      },
      email(value) {
        if (!value) {
          return "campo obrigatório";
        }
        if (!validateEmail(value)) {
          return "email inválido";
        }

        return true;
      },
      password(value) {
        if (value && value.length < 3) {
          return "minímo 3 caracteres";
        }

        return true;
      },
    };

    // Create a form context with the validation schema
    const { handleSubmit } = useForm({
      validationSchema: userSchema,
    });

    // No need to define rules for fields
    const { value: name, errorMessage: nameError } = useField("name");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } =
      useField("password");

    function onInvalidSubmit() {
      const notificationInfos = {
        msg: i18n.global.t('check_fields_and_try_again'),
        status: "error",
        show: true,
      };

      emit("handleNotification", notificationInfos);
    }

    const createUser = handleSubmit((values) => {
      let inputs = userForm.value.elements;
      let modulos_sip = inputs["modulos_sip[]"];
      let modulos_dialplan = inputs["modulos_dialplan[]"];
      let modulos_pabx = inputs["modulos_pabx[]"];
      let modulos_gerenciamento = inputs["modulos_gerenciamento[]"];
      let modulos_relatorios_persa = inputs["modulos_relatorios_persa[]"];
      let modulos_callcenter = inputs["modulos_callcenter[]"];
      let modulos_discador = inputs["modulos_discador[]"];

      modulos_sip = Array.from(modulos_sip)
        .map((item) => {
          if (item.checked) return item.id.replace(/_\d+$/, "");
        })
        .filter((item) => item);

      modulos_dialplan = Array.from(modulos_dialplan)
        .map((item) => {
          if (item.checked) return item.id.replace(/_\d+$/, "");
        })
        .filter((item) => item);

      modulos_pabx = Array.from(modulos_pabx)
        .map((item) => {
          if (item.checked) return item.id.replace(/_\d+$/, "");
        })
        .filter((item) => item);

      modulos_gerenciamento = Array.from(modulos_gerenciamento)
        .map((item) => {
          if (item.checked) return item.id.replace(/_\d+$/, "");
        })
        .filter((item) => item);

      modulos_relatorios_persa = Array.from(modulos_relatorios_persa)
        .map((item) => {
          if (item.checked) return item.id.replace(/_\d+$/, "");
        })
        .filter((item) => item);


      if (modulos_callcenter.checked) {
        modulos_callcenter = ['gerenciamento_gestor'];
      } else {
        modulos_callcenter = [];
      }

      if (modulos_discador.checked) {
        modulos_discador = ['discador'];
      } else {
        modulos_discador = [];
      }


      //login
      userInfos.value.nome = values.name;
      userInfos.value.login = values.email;
      userInfos.value.senha = values.password;

      //permissions
      userInfos.value.modulos_sip = modulos_sip;
      userInfos.value.modulos_dialplan = modulos_dialplan;
      userInfos.value.modulos_pabx = modulos_pabx;
      userInfos.value.modulos_gerenciamento = modulos_gerenciamento;
      userInfos.value.modulos_relatorios_persa = modulos_relatorios_persa;
      userInfos.value.modulos_callcenter = modulos_callcenter;
      userInfos.value.modulos_discador = modulos_discador;
      
      const server = route().params.server;
      userInfos.value.id_empresas = route().params.id;

      PabxUserStore.createUser(server, userInfos.value)
        .then(({ data }) => {
          const notificationInfos = {
            msg: data.message,
            status: "success",
            show: true,
          };

          emit("handleNotification", notificationInfos);
          emit("refreshList");
          emit("closeModal");
        })
        .catch((error) => {
          const notificationInfos = {
            msg: error,
            status: "error",
            show: false,
          };

          emit("handleNotification", notificationInfos);
        });
    }, onInvalidSubmit);

    onMounted(() => {
      userName.value.focus();
      getModules();
    });

    return {
      userInfos,
      permissions,
      capitalizeString,
      createUser,
      userSchema,
      userForm,
      name,
      nameError,
      email,
      emailError,
      password,
      passwordError,
      toggleAllPermissions,
      toggleModulePermissions,
      checkAllInput,
      userName,
    };
  },
};
</script>
<style scoped>
/* Toggle */
.toggle:checked ~ .dot {
  transform: translateX(100%);
}
</style>
