<template>
  <section class="sections__default relative">
    <Header>
      <h1 class="header__title">{{ $t("plans") }}</h1>
    </Header>
    <div class="px-5">
      <div class="flex flex-wrap mb-3 gap-5 items-center">
        <CreatePlan
          :btnText="$t('new_plan')"
          @click="isOpen = 'create-plan-modal'"
        />
        <SearchPlan @search-action="searchPlan" />
      </div>
      <MainTable>
        <template v-slot:ths>
          <th class="main__table--th rounded-tl-lg w-32">{{ $t("name") }}</th>
          <th class="main__table--th w-24">{{ $t("sip_branchs") }}</th>
          <th class="main__table--th w-40">{{ $t("record_branchs") }}</th>
          <th class="main__table--th w-36">{{ $t("sip_trunks") }}</th>
          <th class="main__table--th w-44">{{ $t("satisfaction_survey") }}</th>
          <th class="main__table--th w-24">{{ $t("ura") }}</th>
          <th class="main__table--th w-60 lg:w-1/6 text-center rounded-tr-lg">
            {{ $t("actions") }}
          </th>
        </template>
        <template v-slot:trs>
          <tr
            v-for="(plan, index) in plans"
            :key="plan[index]"
            class="dashboard__tbody--tr"
          >
            <td class="main__table--td truncate max-w-[280px]">
              {{ plan.nome }}
            </td>
            <td class="main__table--td">
              {{ plan.limit_ramais_sip ?? "Ilimitado" }}
            </td>
            <td class="main__table--td">
              {{ plan.limit_ramais_gravacao ?? "Ilimitado" }}
            </td>
            <td class="main__table--td">
              {{ plan.limit_troncos_sip ?? "Ilimitado" }}
            </td>
            <td class="main__table--td">
              {{ plan.limit_pesquisa_satisfacao ?? "Ilimitado" }}
            </td>
            <td class="main__table--td">{{ plan.limit_ura ?? "Ilimitado" }}</td>
            <td class="main__table--td flex items-center gap-5 justify-center">
              <button
                @click="editPlan(plan, index)"
                class="edit__button rounded-md shadow-sm shadow-zinc-300 dark:shadow-zinc-900 hover:shadow-none cursor-pointer flex items-center gap-2"
              >
                <PencilIcon class="w-5 h-5" />
                <span>{{ $t("edit") }}</span>
              </button>
              <button
                @click="handleDeletePlanModal(plan)"
                class="bg-red-500 hover:bg-red-600 shadow-sm relative group text-white dark:text-zinc-300/90 flex items-center py-1 px-1.5 rounded-md gap-2 text-xs"
              >
                <TrashIcon class="w-5 h-5" />
                <span>{{ $t("remove") }}</span>
              </button>
            </td>
          </tr>
        </template>
      </MainTable>
    </div>
  </section>

  <PlanModal
    v-if="isOpen === 'create-plan-modal'"
    @close-modal="closeModal"
    @refresh-plans="getPlans"
    @handle-notification="handleNotification"
  />

  <EditPlanModal
    v-if="isOpen === 'edit-plan-modal'"
    :plan="currentPlan"
    @close-modal="closeModal"
    @refresh-plans="getPlans"
    @handle-notification="handleNotification"
  />

  <DeletePlanModal
    v-if="isOpen === 'delete-plan-modal'"
    :plan="currentPlan"
    @close-modal="closeModal"
    @handle-delete-action="deletePlan"
  />

  <Teleport to="body">
    <Transition name="notification">
      <Notification
        v-if="notificationState.show"
        :msg="notificationState.msg"
        :status="notificationState.status"
      />
    </Transition>
  </Teleport>
</template>

<script>
import Header from "../../../components/header/TheHeader.vue";
import MainTable from "../../../components/table/MainTable.vue";
import CreatePlan from "../../../components/buttons/TheCreator.vue";
import EditPlanModal from "./modal/EditPlan.vue";
import SearchPlan from "../../../components/inputs/TheSearcher.vue";
import PlanModal from "./modal/CreatePlan.vue";
import DeletePlanModal from "./modal/DeletePlan.vue";
import Notification from "../../../components/notification/Notification.vue";
import { ref, reactive } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { usePlanStore } from "../../../../store/PlanStore.js";
import { TrashIcon, PencilIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Header,
    MainTable,
    CreatePlan,
    EditPlanModal,
    SearchPlan,
    PlanModal,
    DeletePlanModal,
    Notification,
    TrashIcon,
    PencilIcon,
  },
  setup() {
    let isOpen = ref(false);
    let notificationState = reactive({});
    let currentPlan = ref({});

    const PlanStore = usePlanStore();
    let plans = computed(() => PlanStore.plans);

    const closeModal = () => (isOpen.value = false);

    function searchPlan(text) {
      getPlans(text);
    }

    function handleNotification(notificationInfos) {
      const { msg, status, show } = notificationInfos;

      notificationState.msg = msg;
      notificationState.status = status;
      notificationState.show = show;

      setTimeout(() => {
        notificationState.msg = "";
        notificationState.status = "";
        notificationState.show = false;
      }, 2000);
    }

    function getPlans(planName) {
      PlanStore.getPlans(planName)
        .then(({ data }) => (PlanStore.plans = data.data))
        .catch((error) => {
          throw new Error(error);
        });
    }

    function editPlan(plan) {
      currentPlan.value = plan;

      isOpen.value = "edit-plan-modal";
    }

    function handleDeletePlanModal(plan) {
      currentPlan.value = plan;
      isOpen.value = "delete-plan-modal";
    }

    function deletePlan(planId) {
      PlanStore.deletePlan(planId)
        .then(({ data }) => {
          const notificationInfos = {
            msg: data.message,
            status: "success",
            show: true,
          };
          handleNotification(notificationInfos);
          closeModal();
          getPlans();
        })
        .catch((error) => {
          const notificationInfos = {
            msg: error,
            status: "error",
            show: false,
          };
          handleNotification(notificationInfos);
        });
    }

    onMounted(() => getPlans());
    return {
      isOpen,
      closeModal,
      PlanStore,
      plans,
      getPlans,
      handleNotification,
      notificationState,
      currentPlan,
      handleDeletePlanModal,
      deletePlan,
      editPlan,
      searchPlan,
    };
  },
};
</script>
