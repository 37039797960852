<template>
  <div :class="{ dark }" class="flex">
    <Menu v-if="sidebarMenu" />
    <slot></slot>
  </div>
</template>

<script setup>
import Menu from "./components/menu/Index.vue";
import { onMounted, onUpdated, provide, ref } from "vue";

let dark = ref(true);
let sidebarMenu = ref(true);

if (localStorage.getItem("dark") === "true") dark.value = true;
if (localStorage.getItem("dark") === "false") dark.value = false;

const toggleDark = () => {
  dark.value = !dark.value;
  localStorage.setItem("dark", dark.value);
};

const showSidebar = () => (sidebarMenu.value = true);
const hideSidebar = () => (sidebarMenu.value = false);

let currentRoute = ref(null);

let requestCounter = 0;
localStorage.setItem("requestCounter", requestCounter);

provide("toggleDark", toggleDark);
provide("dark", dark);
provide("hideSidebar", hideSidebar);
provide("showSidebar", showSidebar);
provide("currentRoute", currentRoute);

onUpdated(() => (currentRoute.value = route().current()));
onMounted(() => (currentRoute.value = route().current()));
</script>

<style>
.notification-enter-active,
.notification-leave-active {
  opacity: 100;
  transition: all 300ms ease;
  transform: scale(1);
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: scale(0.9);
  top: -10%;
}

.modal__area {
  height: calc(100vh - 64px);
}

@media screen and (min-width: 640px) {
  .modal__area {
    height: max-content;
  }
}
</style>
