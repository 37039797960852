<template>
  <div class="overflow-x-auto">
    <table class="table-fixed lg:table-auto w-full">
      <thead>
        <tr>
          <slot name="ths"></slot>
        </tr>
      </thead>
      <tbody>
        <slot name="trs"></slot>
      </tbody>
    </table>
  </div>
</template>

<script>

</script>