<template>
  <Switch
    v-model="enabled"
    :class="enabled ? 'bg-emerald-600' : 'bg-zinc-500'"
    class="relative inline-flex flex-shrink-0 h-3 w-7 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
  >
    <span class="sr-only">{{ $t("toggle_value") }}</span>
    <span
      aria-hidden="true"
      :class="enabled ? 'translate-x-4' : 'translate-x-0'"
      class="pointer-events-none inline-block h-[9px] w-[9px] rounded-full bg-zinc-100 shadow-lg transform ring-0 transition ease-in-out duration-200"
    />
  </Switch>
</template>

<script>
import { ref, watch } from "vue";
import { Switch } from "@headlessui/vue";

export default {
  props: ["isEnabled"],
  emit: ["toggleChanged"],
  components: {
    Switch,
  },
  setup(props, { emit }) {
    let enabled = ref(props.isEnabled);
    watch(enabled, () => {
      emit("toggleChanged", enabled.value);
    });
    return {
      enabled,
    };
  },
};
</script>
