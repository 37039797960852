<template>
  <section class="sections__default relative">
    <Header>
      <h1 class="header__title">{{ $t("pabx_template") }}</h1>
    </Header>
    <div class="px-5">
      <CreateTemplate
        class="py-0.5 2xl:py-1 border border-transparent animate__animated animate__fadeInDown animate__faster"
        :btnText="$t('add_template')"
        @click="isOpen = 'create-template-modal'"
      />
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 gap-5 pt-4 items-start"
      >
        <PabxServer
          v-for="(template, index) in templates"
          :key="index"
          :template="template"
          @handle-notification="handleNotification"
          @handle-edit-template="handleEditTemplate"
          @handle-delete-template="handleDeleteTemplate"
        />
      </div>
    </div>
  </section>

  <CreateTemplateModal
    v-if="isOpen === 'create-template-modal'"
    @close-modal="closeModal"
    @notificate="handleNotification"
    @refresh-list="getTemplates"
  />

  <EditTemplate
    v-if="isOpen === 'edit-template-modal'"
    :template="currentTemplate"
    @close-modal="closeModal"
    @notificate="handleNotification"
    @refresh-list="getTemplates"
  />

  <DeleteTemplateModal
    v-if="isOpen === 'delete-template-modal'"
    :item-to-be-deleted="currentTemplate"
    @handle-delete-action="deleteTemplate"
    @close-modal="closeModal"
  />

  <Teleport to="body">
    <Transition name="notification">
      <Notification
        v-if="notificationState.show"
        :msg="notificationState.msg"
        :status="notificationState.status"
      />
    </Transition>
  </Teleport>
</template>

<script setup>
import Header from "../../../components/header/TheHeader.vue";
import CreateTemplate from "../../../components/buttons/TheCreator.vue";
import PabxServer from "./buttons/PabxChoice.vue";
import CreateTemplateModal from "./modal/CreateTemplate.vue";
import DeleteTemplateModal from "../../../components/modal/DeleteModal.vue";
import Notification from "../../../components/notification/Notification.vue";
import { ref, reactive } from "@vue/reactivity";
import { useTemplateStore } from "../../../../store/TemplateStore.js";
import { computed, onMounted } from "@vue/runtime-core";
import EditTemplate from "./modal/EditTemplateModal.vue";

const TemplateStore = useTemplateStore();

let isOpen = ref(false);
let notificationState = reactive({
  status: "",
  msg: "",
  show: false,
});
let currentTemplate = ref({});

const closeModal = () => (isOpen.value = false);

const handleEditTemplate = (template) => {
  currentTemplate.value = template;

  isOpen.value = "edit-template-modal";
};

const handleDeleteTemplate = (template) => {
  currentTemplate.value = template;
  isOpen.value = "delete-template-modal";
};

const deleteTemplate = (template) => {
  TemplateStore.deleteTemplate(template)
    .then(({ data }) => {
      const notificationInfos = {
        msg: data.message,
        status: "success",
        show: true,
      };

      closeModal();
      handleNotification(notificationInfos);
      getTemplates();
    })
    .catch((error) => {
      const notificationInfos = {
        msg: error,
        status: "error",
        show: false,
      };

      handleNotification(notificationInfos);
    });
};

const templates = computed(() => TemplateStore.templates);

function handleNotification(notificationInfos) {
  const { msg, status, show } = notificationInfos;

  notificationState.msg = msg;
  notificationState.status = status;
  notificationState.show = show;

  setTimeout(() => {
    notificationState.msg = "";
    notificationState.status = "";
    notificationState.show = false;
  }, 2000);
}

function getTemplates() {
  const { url } = JSON.parse(localStorage.getItem("auth_payload"));

  TemplateStore.getTemplates(url)
    .then(({ data }) => {
      TemplateStore.templates = data.data;
    })
    .catch((error) => {});
}

function createNewTemplate(e) {
  closeModal();
}

onMounted(() => getTemplates());
</script>
