<template>
  <article
    @click="handleMigration(pabxInfos.status)"
    class="w-full rounded-lg bg-white dark:bg-zinc-800 shadow shadow-zinc-300 hover:shadow-none dark:shadow-zinc-900 relative"
  >
    <div
      v-if="pabxInfos.status === 4"
      class="absolute inset-0 w-full h-full z-50 bg-transparent"
    ></div>
    <header class="flex items-start justify-between m-4 gap-x-5">
      <div class="flex flex-col">
        <h1
          :title="pabxInfos.name"
          class="text-zinc-500 dark:text-zinc-300 max-w-[200px] truncate font-medium"
        >
        {{ pabxInfos.id }} - {{ pabxInfos.name }}
        </h1>
        <p class="text-zinc-400 dark:text-zinc-500 text-xs">
          {{ pabxInfos.type }} - {{ pabxInfos.core_server }}
        </p>
      </div>
      <div class="flex items-center gap-x-1 relative">
        <button
          :disabled="pabxInfos.status === 4"
          @click="toggleFavorite(pabxInfos)"
          class="relative"
        >
          <StarIconSolid
            v-if="pabxInfos.favoritos"
            class="w-6 h-6 text-amber-500"
          />
          <StarIconOutline v-else class="w-6 h-6 text-zinc-300" />
          <span class="sr-only">{{ $t("toggle_pabx_favorite") }}</span>
        </button>
        <button
          :disabled="pabxInfos.status === 4"
          @click="openPabxMenu"
          class="relative text-zinc-500 dark:text-zinc-300"
        >
          <DotsVerticalIcon class="w-6 h-6" />
          <span class="sr-only">{{ $t("toggle_edit_pabx_options") }}</span>
        </button>
        <Transition name="menu">
          <ul
            v-if="isOpen === 'pabx-menu'"
            class="absolute right-5 top-8 bg-white dark:bg-zinc-800 text-zinc-500 dark:text-zinc-300 overflow-hidden shadow shadow-zinc-300 dark:shadow-zinc-900 rounded-md w-32"
            ref="menu"
          >
            <li>
              <button
                @click="editPabx"
                class="p-3 flex items-center gap-3 hover:bg-zinc-100 dark:hover:bg-zinc-700/80 w-full"
              >
                <PencilIcon class="w-4 h-4" />
                <span>{{ $t("edit") }}</span>
              </button>
            </li>
            <li>
              <button
                class="p-3 flex items-center gap-3 hover:bg-zinc-100 dark:hover:bg-zinc-700/80 w-full"
                @click="deletePabx(pabxInfos)"
              >
                <TrashIcon class="w-4 h-4" />
                <span>{{ $t("delete") }}</span>
              </button>
            </li>
          </ul>
        </Transition>
      </div>
    </header>

    <div class="flex items-center gap-5 m-4 text-xs">
      <span
        class="pt-0.5 pb-1 px-2 rounded-full text-white"
        :class="[
          pabxInfos.status === 0 ? 'bg-red-500 dark:bg-red-600' : '',
          pabxInfos.status === 1 ? 'bg-emerald-500 dark:bg-emerald-600' : '',
          pabxInfos.status === 2 ? 'bg-amber-500 dark:bg-amber-600' : '',
          pabxInfos.status === 3 ? 'bg-orange-500 dark:bg-orange-600' : '',
          pabxInfos.status === 4 ? 'bg-indigo-500 dark:bg-indigo-600' : '',
        ]"
      >
        {{
          pabxInfos.status === 0
            ? "Desativado"
            : pabxInfos.status === 1
            ? "Operando"
            : pabxInfos.status === 2
            ? "Bloqueio Parcial"
            : pabxInfos.status === 3
            ? "Bloqueio Total"
            : "Migrando"
        }}
      </span>
      <span
        class="bg-zinc-500 dark:bg-zinc-600 pt-0.5 pb-1 px-2 rounded-full text-white"
      >
        {{ pabxInfos.ramais }} {{ $t("branchs") }}
      </span>
    </div>

    <footer
      class="border-t border-zinc-200 dark:border-zinc-700 flex text-xs 2xl:text-sm"
    >
      <Link
        :href="$route('pabx.pabx.edit')"
        :data="{ id: pabxInfos.id, server: pabxInfos.id_core_server }"
        class="rounded-bl-lg hover:bg-zinc-200 dark:hover:bg-zinc-700 w-full py-4 flex items-center justify-center gap-1 border-r border-zinc-200/70 dark:border-zinc-700 text-zinc-600/80 dark:text-zinc-400/80"
      >
        <UsersIcon class="w-5 h-5" />
        <span>{{ $t("users") }}</span>
      </Link>

      <a
        :href="pabxInfos.url_pcc"
        target="_blank"
        class="rounded-br-lg hover:bg-zinc-200 dark:hover:bg-zinc-700 w-full py-4 flex items-center justify-center gap-1 text-zinc-600/80 dark:text-zinc-400/80"
      >
        <ExternalLinkIcon class="w-5 h-5" />
        <span>{{ $t("access") }} </span>
      </a>
    </footer>
  </article>
</template>
<script>
import {
  DotsVerticalIcon,
  ExternalLinkIcon,
  UserIcon,
  UsersIcon,
  PencilIcon,
  TrashIcon,
  StarIcon as StarIconOutline,
} from "@heroicons/vue/outline";
import { StarIcon as StarIconSolid } from "@heroicons/vue/solid";
import { Link } from "@inertiajs/inertia-vue3";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { usePabxStore } from "../../../../../store/PabxStore";

export default {
  props: ["pabxInfos"],
  emits: [
    "refreshList",
    "handleNotification",
    "editPabx",
    "deletePabx",
    "pabxMigration",
  ],
  components: {
    DotsVerticalIcon,
    ExternalLinkIcon,
    UserIcon,
    UsersIcon,
    StarIconOutline,
    StarIconSolid,
    PencilIcon,
    TrashIcon,
    Link,
  },

  setup(_, { emit }) {
    const PabxStore = usePabxStore();
    let isOpen = ref(false);

    function toggleFavorite(pabx) {
      let id = pabx.id;
      let isFavorite = pabx.favoritos ?? 0;

      if (isFavorite === 1) isFavorite = 0;
      else isFavorite = 1;

      PabxStore.toggleFavoritePabx(id, isFavorite).then(({ data }) => {
        const notificationInfos = {
          msg: data.message,
          status: "success",
          show: true,
        };

        emit("handleNotification", notificationInfos);
        emit("refreshList");
      });
    }

    function openPabxMenu() {
      isOpen.value = "pabx-menu";
    }

    function editPabx() {
      emit("editPabx");
    }

    function deletePabx(pabx) {
      emit("deletePabx", pabx);
    }

    function handleMigration(status) {
      if (status === 4) {
        emit("pabxMigration");
        return;
      }
    }

    const menu = ref(null);

    onClickOutside(menu, () => (isOpen.value = null));

    return {
      toggleFavorite,
      openPabxMenu,
      isOpen,
      menu,
      deletePabx,
      editPabx,
      handleMigration,
    };
  },
};
</script>
<style>
.menu-enter-active,
.menu-leave-active {
  transition: all 300ms ease;
}

.menu-enter-from,
.menu-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
