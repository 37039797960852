import { defineStore } from "pinia";

import axios from "axios";
import { baseURL } from "../app";

const config = {
  baseURL: `${baseURL}`,
};

const tokenRequest = axios.create(config);

export const useLoginStore = defineStore("useLoginStore", {
  state: () => {
    return {};
  },

  actions: {
    async login(user) {
      return tokenRequest.post("/api/resale/login", user);
    },

    async handleToken(infos) {
      return tokenRequest.post("/oauth/token", { ...infos });
    },
  },
});
