<template>
  <aside
    class="w-full md:w-96 md:h-[calc(100vh-77px)] p-5 md:p-3 2xl:p-5 bg-zinc-100 dark:bg-zinc-700/40 border-r border-zinc-200 dark:border-zinc-700 overflow-y-auto"
  >
    <div class="flex flex-col gap-2 relative">
      <label
        class="text-zinc-600 dark:text-zinc-300 uppercase text-xs"
        for="initialDate"
      >
        {{ $t("initial_date") }}
      </label>
      <input
        class="py-1.5 px-2 rounded-md shadow-sm shadow-zinc-200 dark:shadow-zinc-900 dark:bg-zinc-900/80 text-zinc-500 dark:text-zinc-300 text-xs lg:text-sm 2xl:text-base"
        type="datetime-local"
        v-model="initialDate"
        id="initialDate"
      />
    </div>
    <div class="flex flex-col gap-2 my-7">
      <label
        class="text-zinc-600 dark:text-zinc-300 uppercase text-xs"
        for="finishDate"
      >
        {{ $t("finish_date") }}
      </label>
      <input
        class="py-1.5 px-2 rounded-md shadow-sm shadow-zinc-200 dark:shadow-zinc-900 dark:bg-zinc-900/80 text-zinc-500 dark:text-zinc-300 text-xs lg:text-sm 2xl:text-base"
        type="datetime-local"
        v-model="finalDate"
        id="finishDate"
      />
    </div>
    <div class="flex flex-col gap-2 my-7">
      <span class="text-zinc-600 dark:text-zinc-300 uppercase text-xs">
        {{ $t("user") }}
      </span>
      <Select
        class="w-full"
        :customClasses="true"
        :selectOptions="selectUsers"
        @select-changed="selectUserChanged"
      />
    </div>
    <div class="flex flex-col gap-2 my-7">
      <span class="text-zinc-600 dark:text-zinc-300 uppercase text-xs">
        {{ $t("module") }}
      </span>
      <Select
        class="w-full"
        :customClasses="true"
        :selectOptions="selectModules"
        @select-changed="selectModuleChanged"
      />
    </div>
    <div class="flex flex-col gap-2 my-7">
      <span class="text-zinc-600 dark:text-zinc-300 uppercase text-xs">
        {{ $t("type") }}
      </span>
      <Select
        class="w-full"
        :customClasses="true"
        :selectOptions="selectTypes"
        @select-changed="selectTypeChanged"
      />
    </div>
    <div class="flex flex-col gap-2 my-7">
      <label
        class="text-zinc-600 dark:text-zinc-300 uppercase text-xs"
        for="initialDate"
      >
        {{ $t("details") }}
      </label>
      <input
        class="py-1.5 px-2 rounded-md shadow-sm shadow-zinc-200 dark:shadow-zinc-900 dark:bg-zinc-900/80 dark:text-zinc-300"
        type="text"
        v-model="description"
      />
    </div>
    <div>
      <button
        @click="getAuditoriums"
        class="w-full py-1.5 px-2 text-center bg-indigo-500/80 dark:bg-indigo-600/90 hover:bg-indigo-500 dark:hover:bg-indigo-500 text-white rounded-md shadow-sm shadow-indigo-500"
      >
        {{ $t("search") }}
      </button>
    </div>
  </aside>
</template>
<script>
import Select from "../../../components/select/Index.vue";
import { useNow, useDateFormat } from "@vueuse/core";
import { useAuditoriumStore } from "../../../../store/AuditoriumStore";
import { onMounted, ref } from "vue";

export default {
  components: {
    Select,
  },
  emits: ["handleNotification"],
  setup(_, { emit }) {
    const AuditoriumStore = useAuditoriumStore();

    let formattedInitialDate = useDateFormat(useNow(), "YYYY-MM-DDT00:00");
    let formattedFinishDate = useDateFormat(useNow(), "YYYY-MM-DDTHH:mm");

    let initialDate = ref(formattedInitialDate.value);
    let finalDate = ref(formattedFinishDate.value);

    const selectUsers = ref([{ id: -1, name: "Nenhum usuário" }]);
    const selectModules = ref([{ id: -1, name: "Nenhum módulo" }]);
    const selectTypes = ref([{ id: -1, name: "Nenhum tipo" }]);

    let currentUser = ref(null);
    let currentModule = ref(null);
    let currentType = ref(null);

    let description = ref("");

    function getUsers() {
      AuditoriumStore.getSelectUsers().then(({ data }) => {
        let response = data.data;

        if (response && response.length > 0) {
          selectUsers.value = [];

          for (let index in response) {
            const newUser = {
              name: response[index].usuario,
              value: index,
            };

            selectUsers.value.push(newUser);
          }
          selectUsers.value.unshift({ name: "Todos", value: "" });
        }
      });
    }

    function getModules() {
      AuditoriumStore.getModules().then(({ data }) => {
        let response = data.data;

        if (response && response.length > 0) {
          selectModules.value = [];

          for (let index in response) {
            let newModule = {
              name: response[index].modulo,
              value: index,
            };

            selectModules.value.push(newModule);
          }
          selectModules.value.unshift({ name: "Todos", value: "" });
        }
      });
    }

    function getTypes() {
      AuditoriumStore.getTypes().then(({ data }) => {
        let response = data.data;

        if (response && response.length > 0) {
          selectTypes.value = [];

          for (let index in response) {
            let newType = {
              name: response[index].name,
              value: response[index].value,
            };

            selectTypes.value.push(newType);
          }
          selectTypes.value.unshift({ name: "Todos", value: "" });
        }
      });
    }

    function selectUserChanged(user) {
      currentUser.value = user;
    }

    function selectModuleChanged(module) {
      currentModule.value = module;
    }

    function selectTypeChanged(type) {
      currentType.value = type;
    }

    function getAuditoriums() {
      initialDate.value = initialDate.value.replace("T", " ");
      finalDate.value = finalDate.value.replace("T", " ");

      let searchParams = {
        initial_date: initialDate.value,
        final_date: finalDate.value,
        user: currentUser.value.name,
        module: currentModule.value.name,
        type: currentType.value.value,
        details: description.value,
      };

      if (!currentUser.value.value) searchParams.user = "";
      if (!currentModule.value.value) searchParams.module = "";
      if (!currentType.value.value) searchParams.module = "";

      AuditoriumStore.getAuditoriumList(searchParams)
        .then((data) => {
          AuditoriumStore.auditoriumList = data.data.data;
        })
        .catch((error) => {
          let msg =
            error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ][0];

          const notificationInfos = {
            msg,
            status: "error",
            show: true,
          };

          emit("handleNotification", notificationInfos);
        });
    }

    onMounted(() => {
      getUsers();
      getModules();
      getTypes();
    });

    return {
      selectUsers,
      selectModules,
      selectTypes,
      selectUserChanged,
      selectModuleChanged,
      selectTypeChanged,
      initialDate,
      finalDate,
      description,
      getAuditoriums,
    };
  },
};
</script>
