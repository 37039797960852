import { defineStore } from "pinia";
import request from "../http";

export const useTemplateStore = defineStore("useTemplateStore", {
  state: () => {
    return {
      templates: [],
    };
  },

  actions: {
    async getServerSip() {
      return request.get("/core_server");
    },

    async getTemplates(url = "") {
      return request.get("/template", {
        params: {
          url
        }
      });
    },

    async createTemplate(template) {
      return request.post(`/server/${template.id_core_servers}/template`, {
        id_usuario_revendedor: template.id_usuario_revendedor,
        name: template.name,
        id_core_servers: template.id_core_servers,
      });
    },

    async editTemplateName(template) {
      return request.put(
        `/server/${template.id_core_server}/template/${template.id}`,
        {
          name: template.name,
          id_core_servers: template.id_core_server,
        }
      );
    },

    async deleteTemplate(template) {
      return request.delete(
        `/server/${template.id_core_server}/template/${template.id}`
      );
    },
  },
});
